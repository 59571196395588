<template>
  <div class="content-profile pb-3">
    <v-row class="sub-content" no-gutters>
      <v-col cols="4">
        <ImageProfile :profile="profile" :userProfile="userProfile" />
      </v-col>

      <v-col cols="8">
        <div class="width-sub-content">
          <span
            v-if="displayMemberLevel && layoutDisplay == 'Standard'"
            class="cursor-pointer f-sm d-inline-flex align-items-center"
            @click="$router.push('/member/' + profile.member_level_id)"
          >
            <c-img
              v-if="profile.icon"
              :src="profile.icon"
              class="w-100 mr-2"
              style="max-width: 25px"
            />
            <!-- {{ memberLevel }} -->
          </span>
          <span class="name">
            {{ profile.first_name_th }}
            {{ profile.middle_name_th || "" }}
            {{ profile.last_name_th }}
          </span>

          <small class="color-gray f-xs">{{ profile.member_id }}</small>
          <div class="color-gray f-xs">
            <font-awesome-icon
              :icon="['fas', 'birthday-cake']"
              class="icon-color-gray"
            />
            <span class="pl-2">
              {{
                profile.have_year_birth === false
                  ? dateFormat(profile.birthday, "DD/MM")
                  : dateFormat(profile.birthday, "DD/MM/YYYY")
              }}
            </span>
          </div>
          <div class="f-xs">
            <font-awesome-icon
              :icon="['far', 'envelope']"
              class="icon-color-gray"
            />
            <span class="pl-2 color-gray">{{ profile.email || "-" }}</span>
          </div>
          <div class="f-xs">
            <font-awesome-icon
              :icon="['fas', 'phone-alt']"
              class="icon-color-gray"
            />
            <span class="pl-2 color-gray">
              {{ convertTelePhone(profile.telephone) }}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="manage-icon">
      <v-menu location="bottom">
        <template v-slot:activator="{ props }">
          <c-btn flat :ripple="false" type="icon" v-bind="props" size="x-small">
            <font-awesome-icon
              :icon="['fas', 'ellipsis-v']"
              class="icon-edit-profile"
            />
          </c-btn>
        </template>

        <v-list>
          <v-list-item
            @click="$router.push('/manage/profile')"
            link-class="px-3"
          >
            <div class="content-between">
              <span data-lang="profile">{{ $t("profile") }}</span>
            </div>
          </v-list-item>
          <template v-if="displayMemberLevel">
            <v-list-item
              @click="$router.push('/member/' + profile.member_level_id)"
              link-class="px-3"
            >
              <div class="content-between">
                <span data-lang="privilege">{{ $t("privilege") }}</span>
              </div>
            </v-list-item>
          </template>

          <v-list-item @click="$router.push('configuration')" link-class="px-3">
            <div class="content-between">
              <span data-lang="setting">{{ $t("setting") }}</span>
            </div>
          </v-list-item>

          <template v-if="displayLogoutButton">
            <v-list-item @click="$emit('logout')" link-class="px-3">
              <div class="content-between">
                <span data-lang="logout">{{ $t("logout") }}</span>
              </div>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </div>
    <div
      class="content-menu-list bg-gray"
      v-if="displayPointAndChannel && layoutDisplay != 'Card'"
    >
      <template v-if="layoutDisplay == 'Standard'">
        <v-row class="my-0" no-gutters>
          <v-col
            :cols="displayConnectChannel ? 6 : 12"
            :class="[displayConnectChannel ? 'point-panel' : '', 'p-2']"
          >
            <div class="f-xs d-flex align-items-center w-100">
              <span data-lang="total_spend"> {{ $t("total_spend") }} </span>
              :
              <font-awesome-icon
                @click="checkIsBranchPoint"
                :icon="['fas', 'chevron-right']"
                class="highlight-color cursor-pointer ml-auto"
              />
            </div>
            <div class="f-sm highlight-color my-auto">
              <span class="f-bold">
                {{ numeralFormat(profile.total_point) }}
              </span>
              <span data-lang="currency_unit">
                &nbsp;{{ $t("currency_unit") }}
              </span>
            </div>
          </v-col>
          <v-col
            cols="6"
            @click="handleMarketPlace()"
            class="b-r p-2"
            v-if="displayConnectChannel"
          >
            <div class="content-between">
              <div class="f-xs" data-lang="point_channel">
                {{ $t("point_channel") }}:
              </div>
              <font-awesome-icon
                :icon="['fas', 'chevron-right']"
                class="highlight-color cursor-pointer f-xs"
              />
            </div>
            <div class="marketplace-panel">
              <template
                v-for="(market_list, index) of marketPlaceObj.marketplace"
              >
                <div
                  class="icon-marketplace"
                  v-if="index < 4"
                  :key="
                    market_list.shopName +
                    market_list.branchId +
                    '' +
                    market_list.id
                  "
                >
                  <img
                    :src="
                      market_list.marketplaceUrl ||
                      'https://api-hourglass-admin-dev.dosetech.co/media/NewsImage/231026021009526.jpg'
                    "
                    alt="Marketplace icon"
                    :class="[market_list.status ? '' : 'op-low']"
                  />
                </div>
              </template>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-else-if="layoutDisplay != 'Card'">
        <v-row class="px-3 py-2">
          <v-col cols="6">
            <div
              class="d-inline-flex w-100 align-items-center"
              @click="$router.push('/member/' + profile.member_level_id)"
            >
              <!-- <div class="member-tier-icon"> -->
              <img :src="memberLevel.icon" class="member-tier-icon" />
              <!-- </div> -->
              <div>
                <span class="f-sm" data-lang="member_tier">
                  {{ $t("member_tier") }}
                </span>

                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="highlight-color cursor-pointer f-sm ml-2"
                />
                <div class="cursor-pointer f-bold text-nowrap">
                  {{
                    memberLevel.member_level_language[0].display ||
                    memberLevel.name
                  }}
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div
              class="d-inline-flex w-100 align-items-center justify-content-end"
              @click="checkIsBranchPoint()"
            >
              <div class="f-sm">
                <span data-lang="current_point">
                  {{ $t("current_total_spend") }}
                </span>
              </div>
            </div>
            <div class="f-bold highlight-color text-right">
              <!-- <img :src="memberLevel.icon" class="member-tier-icon" /> -->
              <div class="d-flex align-items-center justify-content-end">
                <c-img
                  :src="$store.state.domain + $store.state.theme.point_logo"
                  class="point-logo"
                  v-if="$store.state.theme.point_logo"
                ></c-img>

                <!-- <font-awesome-icon :icon="['fas', 'gift']" class="f-sm mr-2" /> -->
                {{ progressValue }}
              </div>
            </div>
          </v-col>
          <v-col class="py-0">
            <ProfileTierLayout :profile="profile" :memberLevel="memberLevel" />
          </v-col>
          <v-col cols="12">
            <template v-if="displayConnectChannel">
              <hr class="mt-0 mb-1" />
              <div
                class="content-between"
                @click="$router.push('/marketplace')"
              >
                <div class="f-sm">{{ $t("earn_channel") }}</div>

                <div class="marketplace-panel align-items-center">
                  <template
                    v-for="(market_list, index) of marketPlaceObj.marketplace"
                  >
                    <div
                      class="card-marketplace-icon"
                      v-if="index < 4"
                      :key="
                        market_list.shopName +
                        market_list.branchId +
                        '' +
                        market_list.id
                      "
                      @click="to(market_list)"
                    >
                      <img
                        :src="
                          market_list.marketplaceUrl ||
                          'https://api-hourglass-admin-dev.dosetech.co/media/NewsImage/231026021009526.jpg'
                        "
                        alt="Marketplace icon"
                        :class="[market_list.status ? '' : 'op-low']"
                      />
                    </div>
                  </template>
                  <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="highlight-color cursor-pointer ml-auto"
                  />
                </div>
              </div>
            </template>
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
// import VueQrcode from "vue-qrcode";
import picture from "@/assets/images/default-image.png";

import MemberProgress from "@/components/MemberProgress";
import ImageProfile from "@/components/profile/ImageProfile";
import ProfileTierLayout from "@/components/layout/ProfileTierLayout";
export default {
  components: {
    ImageProfile,
    MemberProgress,
    ProfileTierLayout
    // VueQrcode
  },
  props: {
    profile: {
      required: true,
      type: Object
    },
    userProfile: {
      required: false,
      type: Object,
      default: () => {}
    },
    marketPlaceObj: {
      required: true
    },
    memberLevel: {
      required: false
    }
  },
  data() {
    return {
      pictureProfile: picture,
      displayLogoutButton:
        this.$store.state.theme.line_display_logout_button == "1"
          ? true
          : false,
      displayPointAndChannel:
        this.$store.state.theme.line_display_point_channel == "1"
          ? true
          : false,
      displayConnectChannel:
        this.$store.state.theme.display_connect_channel == "1" ? true : false,
      layoutDisplay: this.lineLayout,
      displayMemberLevel:
        this.$store.state.theme.line_display_member_level == "1" ? true : false
    };
  },

  computed: {
    progressValue() {
      return this.numeralFormat(this.profile.total_point);
    }
  },
  created() {
    this.getCompleteProfile();
  },
  methods: {
    async getCompleteProfile() {
      if (this.$store.state.theme.display_profile_complete == 1) {
        const res = await this.$axios(`/api/v1/User/GetCompleteProfile`);
        await this.$store.dispatch("setCompleteProfile", res);

        let bodyStyles = document.body.style;
        if (res.detail.profile == 100) {
          this.$nextTick(() => {
            if (document.getElementById("circle-progress"))
              document
                .getElementById("circle-progress")
                .classList.remove("progress-bar-circle");
          });
        } else {
          this.$nextTick(() => {
            bodyStyles.setProperty(
              "--count-progress",
              Math.round(res.detail.profile)
            );
            document
              .getElementById("circle-progress")
              .setAttribute("count", Math.round(res.detail.profile));
            document.getElementById("circle-progress").style.display = "block";
          });
        }
      }
    },
    dateFormat(value, format) {
      if (!value) return "-";
      if (!format) format = "DD-MM-YYYY";
      if (this.$moment(value).format("YYYY") == "1753") return "-";
      return this.$moment(value).format(format);
    },

    handleMarketPlace() {
      this.$router.push("/marketplace");
    },
    checkIsBranchPoint() {
      if (this.$store.state.theme.is_center_point == "0")
        return this.$router.push("/branchpoint");
      return this.$router.push("/point");
    },
    convertTelePhone(val) {
      if (!val || val == "") return null;

      return `${val.substring(0, 3)}-${val.substring(3, 6)}-${val.substring(
        6,
        10
      )}`;
    },
    to(market_list) {
      if (market_list.salesChannelId != 1)
        this.$router.push(
          `/marketpalce?b_id=${market_list.branchId}&s_id=${market_list.salesChannelId}`
        );
    },
    remainValue(params) {
      let sum = this.memberLevel[params] - this.profile.total_spend;
      return sum <= 0 ? -1 : this.numeralFormat(sum);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "scss/profile.scss";
.manage-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.empty-picture {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 42px;
  transform: translate(-50%, -55%);
}
</style>
