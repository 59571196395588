<template>
  <div class="d-block" v-if="modalShow">
    <v-dialog
      v-model="modalShow"
      class="registerModal"
      ref="registerModal"
      hide-header
      hide-footer
      centered
      body-class="p-0"
      scroll-strategy="none"
      :fullscreen="lineLayout.includes('Minimal')"
      :hide-overlay="lineLayout.includes('Minimal')"
      :transition="
        lineLayout.includes('Minimal') ? 'slide-up-down' : 'fade-transition'
      "
    >
      <WelcomeScreenMinimalTheme
        @close="close"
        v-if="lineLayout.includes('Minimal')"
      />
      <template v-else>
        <div class="text-center p-3">
          <div class="f-bold f-size-18">
            {{ detail[0].title }}
          </div>
          <div>{{ detail[0].description }}</div>
        </div>
        <div class="border-top p-2 text-center btn-confirm-popup">
          <div @click="close">{{ $t("close_window") }}</div>
        </div></template
      >
    </v-dialog>
  </div>
</template>

<script>
import WelcomeScreenMinimalTheme from "@/components/profile/modal/WelcomeScreenMinimalTheme";
export default {
  components: { WelcomeScreenMinimalTheme },
  props: {},
  data() {
    return {
      modalShow: false,
      lineProfile: {
        displayName: ""
      },
      detail: []
    };
  },

  methods: {
    async show() {
      if (this.$cookies.get("popupModalRegister") == "true") {
        await this.getWelcome();
        this.modalShow = true;
        this.lineProfile = this.$cookies.get("user_profile_token");
      }
    },
    close() {
      this.modalShow = false;
      this.$cookies.set("popupModalRegister", false);
    },
    async getWelcome() {
      const res = await this.$axios("/api/v1/setting/welcomescreen/popup");
      this.detail = res.detail;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-body {
  margin: auto;
  text-align: center;
}
.image-logo {
  width: 100%;
  height: 100%;
}
</style>
