<template>
  <template v-if="!isCreateInvoice">
    <div class="p-3">
      <h4 class="f-bold" data-lang="billing">{{ $t("billing") }}</h4>
      <div class="panel-invoice">
        <template v-for="address of list" :key="address.id">
          <div class="container-invoice" @click="getById(address.id)">
            <div class="color-primary">
              {{
                address.billing_address_type == 0 ? "บุคคลธรรมดา" : "นิติบุคคล"
              }}
            </div>
            <div class="text-bold text-black">
              {{ address.first_name }}
              <span v-if="address.billing_address_type == 0">
                {{ address.last_name }}</span
              ><span class="color-gray"
                >{{ convertTelePhone(address.telephone) }}
              </span>
            </div>
            <p class="color-gray mb-0 f-md">
              <span v-html="displayAddress(address)"></span>
            </p>
            <div class="box-default" v-if="address.is_default == 1">
              Default
            </div>
          </div>
        </template>
        <div
          class="container-invoice add"
          @click="addInvoiceAddress"
          v-if="list.length < limit || limit == 0"
        >
          <div
            class="d-flex justify-content-center align-items-center"
            style="flex: 1"
          >
            <font-awesome-icon :icon="['fas', 'plus']" class="mr-2" />
            <span data-lang="add_billing">{{ $t("add_billing") }} </span>
            {{ limit > 0 ? `(${list.length}/${limit})` : "" }}
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="content-between px-3 py-2">
      <div class="d-flex align-items-center" @click="back()">
        <font-awesome-icon icon="chevron-left" class="mr-2"></font-awesome-icon>
        <div class="f-bold f-xl" data-lang="info_billing">
          {{ $t("info_billing") }}
        </div>
      </div>
      <div
        @click="fillWithProfile()"
        class="text-underline f-sm color-primary mr-2"
        data-lang="use_as_profile"
      >
        {{ $t("use_as_profile") }}
      </div>
    </div>
    <div class="content-edit-profile px-3 pb-3">
      <div v-html="$config.header_billing_address_form"></div>

      <v-expansion-panels flat v-model="panels" multiple>
        <v-expansion-panel bg-color="white">
          <v-expansion-panel-title static hide-actions flat class="px-0">
            <template #default="{ expanded }">
              <div class="content-between w-100">
                <div class="f-xl f-bold" data-lang="profile_address">
                  {{ $t("profile_address") }}
                </div>
                <div>
                  <font-awesome-icon
                    :icon="['fas', 'chevron-up']"
                    v-if="expanded"
                  />
                  <font-awesome-icon :icon="['fas', 'chevron-down']" v-else />
                </div>
              </div>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text eager>
            <AddressForm
              v-if="!loadField"
              ref="addressForm"
              :fields="customField"
              :companyPrefix="companyPrefix"
              :cloneFields="cloneFields"
              :v$="v$"
              :form="form"
              fieldId="field_billing_type_id"
              defaultId="17"
              :mapField="mapField"
          /></v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <div style="margin: 0 -10px">
        <v-checkbox
          v-model="form.is_default"
          :value="1"
          :label="$t('default')"
          hide-details
          single-line
          :false-value="0"
        ></v-checkbox>
      </div>
      <div v-html="$config.footer_billing_address_form" class="pt-3 pb-5"></div>
    </div>
    <div
      class="register-bottom fixed-bottom shadow"
      :class="{ 'bg-white': !lineLayout.includes('Minimal') }"
    >
      <c-btn
        class="w-100 mb-2"
        variant="outlined"
        @click="$refs.confirmModal.show()"
        v-if="form.id"
      >
        <span data-lang="delete"> {{ $t("delete") }}</span>
      </c-btn>
      <c-btn class="w-100" variant="confirm-primary" @click="submit()">
        <span data-lang="save"> {{ $t("save") }}</span>
      </c-btn>
    </div>
  </template>
  <ModalConfirm
    ref="confirmModal"
    :header="messageModal.header"
    :text="messageModal.text"
    :subText="messageModal.subText"
    :button="messageModal.button"
    @handler="deleteAddress()"
  />
  <Loading v-if="isLoading" />
  <ModalAlert
    ref="modalAlert"
    :text="msg"
    @hide="back"
    :closeText="$t('confirm')"
  />
  <ModalError ref="modalError" :text="modalMessage" />
</template>

<script>
import AddressForm from "@/components/form/AddressForm.vue";
import { billingField, mapBillingField } from "@/assets/json/fieldAddress.js";
import { useVuelidate } from "@vuelidate/core";
import ModalAlert from "@/components/alert-modal/ModalAlert.vue";
import ModalError from "@/components/alert-modal/ModalError.vue";
import DynamicForm from "@/components/form/DynamicForm.vue";
import {
  requiredIf,
  helpers,
  required,
  maxLength,
  minLength,
  numeric,
  email
} from "@vuelidate/validators";
export default {
  name: "InvoicePage",
  components: { AddressForm, ModalAlert, ModalError, DynamicForm },
  data() {
    return {
      isCreateInvoice: false,
      customField: [],
      cloneFields: [],
      socialField: [],
      companyPrefix: [],
      isLoading: false,
      list: [],
      msg: "สำเร็จ",
      modalMessage: "",
      form: {
        user_guid: this.$store.state.shortProfile.user_guid,
        billing_address_type: "บุคคลธรรมดา",
        tax_id: "",
        prefix: "",
        social: [],
        custom_field: [],
        first_name: "",
        last_name: "",
        email: "",
        telephone: "",
        country: "ประเทศไทย",
        telephone_country_id: 1,
        address: "",
        building_name: "",
        alley: "",
        road: "",
        sub_district: "",
        district: "",
        province: "",
        zip_code: "",
        is_default: 0,
        branch_type: "สำนักงานใหญ่"
      },
      mapField: [...mapBillingField],
      limit: 0,

      messageModal: {
        header: "ยืนยันการลบ ?",
        text: "คุณต้องการลบข้อมูลที่อยู่ใบกำกับภาษีนี้หรือ ไม่",
        subText: "",
        button: {
          true: {
            class: ["btn-danger"],
            msg: this.$t("confirm")
          },
          false: {
            class: ["btn-secondary"],
            msg: this.$t("cancel")
          }
        }
      },
      panels: [0, 1],
      loadField: false,
      dynamicField: []
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    for (const item of this.customField) {
      const key = this.checkIsProfile(item.field_billing_type_id);

      if (key) {
        if (item.field_billing_type_id == 2)
          billingField[key] = {
            required: requiredIf(item.required),
            numeric,
            minLength: minLength(13),
            maxLength: maxLength(13)
          };
        else if (item.field_billing_type_id == 7)
          billingField[key] = {
            required: requiredIf(item.required),

            minLength: minLength(9)
          };
        else if (item.field_billing_type_id == 6)
          billingField[key] = {
            required: requiredIf(item.required),
            email
          };
        else billingField[key] = { required: requiredIf(item.required) };
      }
    }
    const checkValue = (val1, v, a) => {
      if (v.required)
        if (!val1) return true;
        else return false;
    };

    return {
      form: billingField,

      socialField: {
        $each: helpers.forEach({
          user_answer: {
            required: requiredIf(checkValue)
          }
        })
      }
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    checkIsProfile(val) {
      const findField = mapBillingField.find(el => el.id == val);

      return findField ? findField.key : false;
    },
    addInvoiceAddress() {
      this.isCreateInvoice = true;
      this.getForm();
    },
    async getList() {
      const result = await this.$axios(
        `/api/v1/user/GetBillingInformationList`
      );

      this.list = result.detail.result;
      this.limit = result.detail.limit;
    },
    async getForm() {
      this.isLoading = true;
      const result = await this.$axios("/api/v1/User/GetBillingForm");
      for (const $fields of result.detail.profileField) {
        let $disable = result.detail.profileField.find(
          el => el.field_dependent == $fields.field_billing_type_id
        );
        if ($fields.field_billing_type_id == 13)
          $fields.field_choices = await this.$store.dispatch("getProvince");

        if (
          $disable &&
          ($fields.field_type_id == 15 || $fields.field_billing_type_id == 16)
        ) {
          $fields.field_choices = [];
        }
      }
      this.cloneFields = JSON.parse(
        JSON.stringify(result.detail.profileField.filter(el => el.is_show == 1))
      );

      this.customField = result.detail.profileField.filter(
        el => el.is_show == 1
      );
      this.mapSocialField(
        result.detail.socialField
          .map(el => {
            return { ...el, social_media_type_id: el.id };
          })
          .filter(el => el.is_show == 1)
      );
      this.companyPrefix = result.detail.companyField;

      this.isLoading = false;
      // console.log(result);
    },
    async handleBillingType(val, field) {
      let f = this.mapField.find(el => field.field_billing_type_id == el.id);
      if (f.key == "billing_address_type") {
        let keyHide = [];
        let keyName = [];
        if (val == "บุคคลธรรมดา") {
          keyHide = ["branch_type"];
          keyName = [
            { text: "คำนำหน้า", key: "3" },
            { text: "ชื่อ", key: "4" }
          ];
        } else if (val == "นิติบุคคล") {
          keyHide = ["last_name"];
          keyName = [
            {
              text: "คำนำหน้าธุรกิจ",
              key: "3",
              choice: this.companyPrefix.field_choices
            },
            { text: "ชื่อธุรกิจ", key: "4" }
          ];
        }

        let hideField = this.mapField
          .filter(el => keyHide.includes(el.key))
          .map(el => el.id);

        this.$nextTick(() => {
          if (hideField.length > 0) {
            let index = 0;
            for (const fields of this.customField) {
              let changeName = keyName.find(
                el => el.key == fields.field_billing_type_id
              );

              if (changeName) {
                fields.name = changeName.text;
                fields.field_choices =
                  changeName.choice || this.cloneFields[index].field_choices;
              }
              if (hideField.includes(fields.field_billing_type_id)) {
                fields.required = false;
                fields.is_address_display = false;
              } else {
                fields.required = this.cloneFields[index].required;
                fields.is_address_display = true;
              }
              index++;
            }
          }
        });
      }
    },
    async submit() {
      try {
        this.v$.form.$touch();

        if (this.v$.form.$error) return;
        this.isLoading = true;

        let result = "";
        let dynamic = [];
        for (const fields of this.dynamicField) {
          let target_id = 0;
          if (fields.field_choices.length > 0) {
            const findFieldId = fields.field_choices.find(
              choice => choice.name == fields.user_answer
            );
            if (findFieldId) {
              target_id = findFieldId.id;
            }
          }
          dynamic.push({
            id: fields.id,
            value: fields.user_answer,
            target_id: target_id
          });
        }
        let objTemp = [];

        for (const x of dynamic) {
          var findField = this.dynamicField.find(field => field.id == x.id);

          if (x.value && typeof x.value == "object") {
            if (x.value.length > 0) {
              for (const a of x.value) {
                const { id } = findField.field_choices.find(
                  choice => choice.name == a
                );
                objTemp.push({
                  id: x.id,
                  value: a,
                  target_id: id
                });
              }
            }
          } else {
            objTemp.push(x);
          }
        }
        let payload = {
          ...this.form,
          custom_field: objTemp.filter(el => el.value)
        };
        payload.social = [];

        payload.billing_address_type =
          payload.billing_address_type == "บุคคลธรรมดา" ? 0 : 1;

        for (const checkForm of this.customField) {
          if (this.isOtherField(checkForm))
            payload[this.checkIsProfile(checkForm.field_billing_type_id)] =
              checkForm.other_value;
        }
        if (this.form.id) {
          result = await this.$axios.put(
            "/api/v1/User/UpdateBillingInformation",
            payload
          );
        } else {
          result = await this.$axios.post(
            "/api/v1/User/CreateBillingInformation",
            payload
          );
        }
        this.isLoading = false;
        if (result.result) {
          this.$refs.modalAlert.show();
          this.getList();
          // this.back();
        } else {
          this.modalMessage = result.message;
          this.$refs.modalError.show();
        }
      } catch (error) {
        this.isLoading = false;
        this.modalMessage = error.message;
        this.$refs.modalError.show();
      }
    },
    async getById(id) {
      this.isLoading = true;
      const result = await this.$axios(
        `/api/v1/user/GetBillingInformationById/${id}`
      );
      this.form.social = result.detail.social;
      await this.getForm();

      result.detail.data.billing_address_type =
        result.detail.data.billing_address_type == 0
          ? "บุคคลธรรมดา"
          : "นิติบุคคล";
      // this.form = result.detail.data;
      this.form = result.detail.data;
      await this.handleBillingType(
        this.form.billing_address_type,
        this.customField.find(el => el.field_billing_type_id == 1)
      );
      this.isCreateInvoice = true;
      this.isLoading = false;
    },
    isOtherField($fields) {
      if ($fields) {
        let find = $fields.field_choices.find(
          el =>
            el.name ==
            this.form[this.checkIsProfile($fields.field_billing_type_id)]
        );
        return find ? (find.choice_type_id == 3 ? true : false) : false;
      }
    },
    back() {
      this.form = {
        user_guid: this.$store.state.shortProfile.user_guid,
        billing_address_type: "บุคคลธรรมดา",
        branch_type: "สำนักงานใหญ่",
        tax_id: "",
        prefix: "",
        first_name: "",
        social: [],
        custom_field: [],
        last_name: "",
        email: "",
        telephone: "",
        telephone_country_id: 1,
        country: "ประเทศไทย",
        address: "",
        building_name: "",
        alley: "",
        road: "",
        sub_district: "",
        district: "",
        province: "",
        zip_code: "",
        is_default: 0
      };
      this.v$.form.$reset();
      this.isCreateInvoice = false;
    },
    async fillWithProfile() {
      this.isLoading = true;
      this.loadField = true;
      let profileField = JSON.parse(
        JSON.stringify(this.$store.state.shortProfile)
      );

      if (profileField.is_company == 1)
        this.form = await {
          ...this.form,
          ...profileField,
          billing_address_type:
            profileField.is_company == 1 ? "นิติบุคคล" : "บุคคลธรรมดา",
          first_name: profileField.first_name_th,
          branch_type: profileField.company_branch_type,
          tax_id: profileField.id_card,
          last_name: profileField.last_name_th,
          sub_district: profileField.subdistrict,
          address: profileField.home_address,
          building_name: profileField.town,
          country: profileField.country || "ประเทศไทย",
          prefix: profileField.prefix
        };
      else {
        this.form = await {
          ...this.form,
          ...profileField,
          billing_address_type:
            profileField.is_company == 1 ? "นิติบุคคล" : "บุคคลธรรมดา",
          first_name: profileField.first_name_th,
          branch_type: profileField.company_branch_type,
          tax_id: profileField.id_card,
          last_name: profileField.last_name_th,
          sub_district: profileField.subdistrict,
          address: profileField.home_address,
          building_name: profileField.town,
          country: profileField.country || "ประเทศไทย",
          prefix: profileField.prefix
        };
      }
      if (!this.form.social)
        this.form.social = await this.$store.dispatch("getUserSocial");
      this.form.social = this.form.social.map(el => {
        return { ...el, id: 0 };
      });

      await this.handleBillingType(
        this.form.billing_address_type,
        this.customField.find(el => el.field_billing_type_id == 1),
        false
      );
      this.loadField = false;
      // await this.$refs.addressForm.loadingField();

      this.isLoading = false;
    },
    async deleteAddress() {
      try {
        this.isLoading = true;
        const result = await this.$axios.delete(
          `/api/v1/User/DeleteBillingInformation/${this.form.id}`
        );
        this.isLoading = false;
        if (result.result) {
          this.$refs.modalAlert.show();
          this.getList();
          // this.back();
        } else {
          this.modalMessage = result.message;
          this.$refs.modalError.show();
        }
      } catch (error) {
        this.isLoading = false;
        this.modalMessage = error.message;
        this.$refs.modalError.show();
      }
    },
    async mapSocialField(array, clone) {
      this.socialField = array.map((el, index) => {
        let answer = null;

        if (this.form.social)
          answer = this.form.social.find(
            form => form.social_media_type_id == el.social_media_type_id
          );

        return {
          ...el,
          answer_id: 0,
          id: clone ? clone[index].id : answer ? answer.id : 0,
          social_media_type_id: el.social_media_type_id,
          field_type_id: 1,
          user_answer: answer ? answer.value : ""
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.container-invoice {
  min-height: 100px;
  position: relative;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid whitesmoke;
  /* align-items: center; */
  /* justify-content: center; */
  &.add {
    border: 1px dashed var(--primary-color);
    color: var(--primary-color);
  }
}
.panel-invoice {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.box-default {
  color: white;
  background-color: green;
  border: 1px solid green;
  border-radius: 0.3rem;
  position: absolute;
  top: 10%;
  right: 3%;
  font-size: 12px;
  padding: 0 5px;
}
</style>
