<template>
  <v-container class="mt-3">
    <v-row>
      <v-col cols="12 my-3">
        <p class="text-center f-xl f-bold" data-lang="telephone_title">
          {{ title || telTitle() }}
        </p>
        <p
          class="text-gray text-center"
          data-lang="email_subtitle"
          v-if="form.login_type == 2"
        >
          {{ description || emailSubTitle }}
        </p>
        <p class="text-gray text-center" data-lang="telephone_subtitle" v-else>
          {{ description || telSubTitle }}
        </p>
      </v-col>
      <v-col cols="12">
        <InputText
          v-if="form.login_type == 1"
          v-model:value="form.Telephone"
          :textFloat="$t('telephone')"
          :placeholder="$t('telephone')"
          type="number"
          name="telephone"
          size="lg"
          isRequired
          @onkeypress="isNumber($event)"
          :isValidate="isValidate"
          :v="v"
          class="f-regular"
          :oninput="input"
          inputmode="numeric"
        />
        <InputText
          v-if="form.login_type == 2"
          v-model:value="form.Email"
          :textFloat="$t('email')"
          :placeholder="$t('email')"
          type="text"
          name="email"
          size="lg"
          isRequired
          :isValidate="isValidateEmail"
          :v="vEmail"
          class="f-regular"
        />
      </v-col>
      <v-col
        class="text-center"
        v-if="$config.display_email_login == '1' && show"
      >
        <span
          class="text-underline"
          @click="$emit('emailLogin', 2)"
          v-if="form.login_type == 1"
          data-lang="email_login_message"
        >
          {{ $t("email_login_message") }}</span
        >
        <span
          class="text-underline"
          @click="$emit('emailLogin', 1)"
          v-if="form.login_type == 2"
          data-lang="telephone_login_message"
        >
          {{ $t("telephone_login_message") }}
        </span>
      </v-col>
    </v-row>

    <div
      class="register-bottom fixed-bottom shadow"
      :class="{ 'bg-white': !lineLayout.includes('Minimal') }"
    >
      <c-btn
        type="button"
        block
        variant="confirm-primary"
        @click="$emit('ValidateTelephone')"
        >{{ $t("confirm") }}</c-btn
      >

      <c-btn
        class="mt-2"
        block
        type="button"
        variant="outline-primary"
        v-if="$store.state.editPhonenumber && lineLayout == 'Compact'"
        @click="
          $router.replace('/manage'), $store.commit('setEditPhoneNumber', false)
        "
        >{{ $t("cancel") }}
      </c-btn>
    </div>
  </v-container>
</template>

<script>
import InputText from "@/components/input/InputText";
import InputTelephoneWithCountry from "@/components/input/InputTelephoneWithCountry";
export default {
  props: {
    title: {
      required: false,
      type: String
    },
    description: {
      required: false,
      type: String
    },
    form: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    },
    isValidate: {
      required: true,
      type: Boolean
    },
    vEmail: {
      required: true,
      type: Object
    },
    isValidateEmail: {
      required: true,
      type: Boolean
    },
    show: {
      required: false,
      default: true
    }
  },
  components: {
    InputText,
    InputTelephoneWithCountry
  },
  data() {
    return {
      telSubTitle: this.$t("telephone_subtitle"),
      emailSubTitle: this.$t("email_subtitle"),
      country: "1"
    };
  },

  methods: {
    telTitle() {
      if (this.form.login_type == 1) return this.$t("telephone_title");
      else return this.$t("email_title");
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    input(e) {
      if (e.target.value.length > 10) {
        e.target.value = e.target.value.slice(0, 10);
        this.form.Telephone = e.target.value;
        return e.preventDefault();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.pos-fix {
  position: fixed;
}
</style>
