<template>
  <div class="content-page">
    <div class="content-telephone">
      <InputTelephone
        :title="title()"
        :description="subTitle()"
        :show="false"
        :form="form"
        @ValidateTelephone="ValidateTelephone"
        :isValidate="v$.form.Telephone.$error"
        :v="v$.form.Telephone"
        :isValidateEmail="v$.form.Email.$error"
        :vEmail="v$.form.Email"
      />
    </div>
    <ModalError ref="modalError" :text="successMessage" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>

<script>
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb.vue";
import InputTelephone from "@/components/input/InputTelephone";
import ModalLoading from "@/components/other/ModalLoading";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  minLength,
  requiredIf,
  maxLength,
  email
} from "@vuelidate/validators";
import ModalError from "@/components/alert-modal/ModalError";
export default {
  components: {
    Breadcrumb,
    ModalError,
    InputTelephone,
    ModalLoading
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "แก้ไขเบอร์โทรศัพท์",
          to: "/manage/profile"
        }
      ],
      form: {
        Telephone: this.$route.query.telephone,
        Email: this.$route.query.email,
        SocialProvider: this.$config.socialProvider,
        SOCIALID: "",
        telephone_country_id: "1",
        telephone_code: "+66",
        login_type: this.$route.params.type || 1
      },
      successMessage: ""
    };
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async ValidateTelephone() {
      try {
        this.v$.form.$touch();

        if (this.v$.form.$error) {
          return;
        }
        this.$refs.modalLoading.show();
        this.form.SOCIALID =
          this.$route.query.userId || this.$config.isLoginLine
            ? this.$cookies.get("user_profile_token").userId
            : this.$cookies.get("telephone_login");

        if (this.form.login_type == 2) {
          this.$axios
            .post(`/api/v1/user/ValidateEmail`, this.form)
            .then(async data => {
              this.$refs.modalLoading.hide(); /*  */
              if (data.result == 1) {
                this.$router.replace({
                  path: "/confirmotpedit",
                  query: {
                    ...this.$route.query,
                    email: this.form.Email,
                    login_type: this.form.login_type
                  }
                });
              } else {
                this.successMessage = "อีเมลถูกใช้งานแล้ว";
                this.$refs.modalError.show();
                this.$refs.modalLoading.hide();
              }
            });
        } else {
          await this.$axios
            .post(`/api/v1/user/verifytelephone`, this.form)
            .then(data => {
              this.isLoading = false;
              if (data.result == 1) {
                this.$router.replace({
                  path: "/confirmotpedit",
                  query: {
                    tel: this.form.Telephone
                  }
                });
              } else {
                this.$refs.modalLoading.hide();
                this.successMessage = data.message;
                this.$refs.modalError.show();
                this.isLoading = false;
              }
            });
        }
      } catch (error) {
        this.$refs.modalLoading.hide();
        this.successMessage = error.message;
        this.$refs.modalError.show();
        this.isLoading = false;
        console.log(error);
        // window.alert(error.message + "error");
      }
    },
    title() {
      if (this.form.login_type == 2) return this.$t("change_email_title");
      return this.$t("change_telephone");
    },
    subTitle() {
      if (this.form.login_type == 2) return this.$t("email_change_subtitle");
      return this.$t("telephone_change_subtitle");
    }
  },
  validations() {
    return {
      form: {
        Telephone: {
          required: requiredIf(function () {
            return this.form.login_type == 1;
          }),
          minLength: minLength(10),
          maxLength: maxLength(10)
        },
        Email: {
          required: requiredIf(function () {
            return this.form.login_type == 2;
          }),
          email
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.content-telephone {
  background-color: #fff;
  border-radius: 5px;
  // height: 100vh
}
.tel-custom {
  font-size: var(--text-3xl);
  font-weight: 600;
}
.des-tel {
  font-weight: 600;
}
.ft-gray {
  color: gray;
}
.remark {
  color: red;
}
</style>
