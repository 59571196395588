<template>
  <div>
    <v-dialog
      v-model="showModal"
      ref="ModalQRUser"
      hide-header
      hide-footer
      centered
      @hidden="hide"
      scroll-strategy="none"
    >
      <div class="h-100 overflow-y-auto">
        <div>
          <template v-if="$store.state.theme.display_profile_complete == '1'">
            <div class="progress-bar-circle shadow-sm" id="circle-progress-2">
              <div
                v-if="userProfile.pictureUrl"
                class="profile-img"
                v-bind:style="{
                  backgroundImage: 'url(' + userProfile.pictureUrl + ')'
                }"
              ></div>
              <div
                v-else-if="profile.picture"
                class="profile-img"
                v-bind:style="{
                  backgroundImage: 'url(' + profile.picture + ')'
                }"
              ></div>
              <div v-else class="profile-img default">
                <div class="empty-picture">{{ profile.first_name_th[0] }}</div>
              </div>
            </div>
            <div
              class="box shadow-sm"
              v-if="$store.state.completeProfile.profile != 100"
              @click="$router.push('/edit-profile-required')"
            >
              <div class="point-box">
                {{ Math.round($store.state.completeProfile.profile) }}%
              </div>
              <div>
                <span class="f-bold">
                  <span data-lang="complate_profile">
                    {{ $t("complate_profile") }}
                  </span>
                  :
                  {{ Math.round($store.state.completeProfile.profile) }}%</span
                >
                <br />
                {{ $store.state.theme.profile_complete_description }}
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-if="userProfile.pictureUrl"
              class="profile-img-2"
              v-bind:style="{
                backgroundImage: 'url(' + userProfile.pictureUrl + ')'
              }"
            ></div>
            <div
              v-else-if="profile.picture"
              class="profile-img-2"
              v-bind:style="{
                backgroundImage: 'url(' + profile.picture + ')'
              }"
            ></div>
            <div v-else class="profile-img-2 default">
              <div class="empty-picture">{{ profile.first_name_th[0] }}</div>
            </div>
          </template>

          <div class="text-center">
            <div class="f-bold">
              {{ profile.first_name_th }} {{ profile.last_name_th }} ({{
                profile.member_id
              }})
              <font-awesome-icon
                @click="$router.push('/manage/profile')"
                :icon="['fas', 'pencil-alt']"
                class="icon-edit-profile f-md"
              />
            </div>
          </div>
          <div class="f-md text-center">
            <font-awesome-icon
              :icon="['fas', 'birthday-cake']"
              class="icon-color-gray"
            />
            <span class="pl-2 profile-ft-color">{{
              profile.have_year_birth === false
                ? dateFormat(profile.birthday, "DD/MM")
                : dateFormat(profile.birthday, "DD/MM/YYYY")
            }}</span>
          </div>
          <div class="f-md text-center">
            <font-awesome-icon
              :icon="['far', 'envelope']"
              class="icon-color-gray"
            />
            <span class="pl-2 profile-ft-color">{{
              profile.email || "-"
            }}</span>
          </div>
          <div class="f-md text-center">
            <font-awesome-icon
              :icon="['fas', 'phone-alt']"
              class="icon-color-gray"
            />
            <span class="pl-2 profile-ft-color">
              {{ convertTelePhone(profile.telephone) || "-" }}
            </span>
          </div>

          <div class="text-center">
            <!--  -->
            <qrcode-vue
              class="qr-width-50"
              :value="
                qrType == 'Telephone' ? profile.telephone : profile.member_id
              "
              render-as="svg"
              background="transparent"
            />
          </div>
          <div class="timer-countdown" v-if="displayCountdown">
            <div>{{ qr_count_time }}</div>
            <div>
              {{ countTime }}
            </div>
          </div>
        </div>
        <c-btn block variant="confirm-primary" @click="hide">
          {{ $t("close_window") }}
        </c-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ImageProfile from "@/components/profile/ImageProfile";
import picture from "@/assets/images/default-image.png";

var x;
export default {
  components: {
    ImageProfile
  },
  props: {
    profile: {
      required: true,
      type: Object
    },
    userProfile: {
      required: false,
      type: Object,
      default: () => {}
    }
  },

  mounted() {},
  data() {
    return {
      showModal: false,
      pictureProfile: picture,
      countTime: "00:00",
      displayCountdown:
        this.$store.state.theme.line_display_code_countdown == "1"
          ? true
          : false,
      qrType: this.$store.state.theme.qr_code
    };
  },
  methods: {
    show() {
      this.showModal = true;
      this.countTimer();

      // setTimeout(() => {
      this.$nextTick(() => {
        if (this.$store.state.theme.display_profile_complete == "1") {
          let bodyStyles = document.body.style;
          bodyStyles.setProperty(
            "--count-progress-2",
            Math.round(this.$store.state.completeProfile.profile)
          );
          document
            .getElementById("circle-progress-2")
            .setAttribute(
              "count",
              Math.round(this.$store.state.completeProfile.profile)
            );
          document.getElementById("circle-progress-2").style.display = "block";
        }
      });
      // }, 200);
    },
    hide() {
      this.showModal = false;
      clearInterval(x);
    },

    countTimer() {
      function padZero(value) {
        value = String(value);
        return value.length == 1 ? "0" + value : value;
      }
      var countDownDate = this.$moment().add(10, "minutes").valueOf();
      x = setInterval(() => {
        var now = this.$moment().valueOf();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = padZero(
          Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        );
        var minutes = padZero(
          Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        );
        var seconds = padZero(Math.floor((distance % (1000 * 60)) / 1000));

        if (distance < 0) {
          return clearInterval(x);
          // this.countTime = "EXPIRED";
        }
        this.$nextTick(() => {
          this.countTime = minutes + " : " + seconds;
        });
      }, 900);
    },
    dateFormat(value, format) {
      if (!value) return "-";
      if (!format) format = "DD-MM-YYYY";
      return this.$moment(value).format(format);
    }
    // convertTelePhone(val) {
    //   if (!val) return null;

    //   return `${val.substring(0, 3)}-${val.substring(3, 6)}-${val.substring(
    //     6,
    //     10
    //   )}`;
    // }
  }
};
</script>

<style lang="scss" scoped>
@import "scss";
.profile-img-2 {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid black;
  border-radius: 50%;
  margin: 1rem auto;
}
.qr-width-50 {
  width: 50% !important;
  height: 50% !important;
  padding: 0.5rem 0;
}
.profile-img.default {
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  left: 6px !important;
  top: 6px !important;
}
.profile-img-2.default {
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  left: 6px !important;
  top: 6px !important;
}
</style>
