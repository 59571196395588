<template>
  <v-container class="account-container">
    <div class="text-center mb-6" v-if="$isShowTab">
      {{
        activeTab === "account"
          ? $t("minimal_profile_tab_title")
          : $t("transaction_header")
      }}
    </div>
    <v-card class="header" flat v-if="!$isShowTab">
      <!-- <v-card-title class="text-center">COS</v-card-title> -->
      <v-tabs v-model="activeTab" hide-slider align-tabs="center">
        <v-tab value="purchase"
          >{{ $t("minimal_transaction_tab_title") }}
        </v-tab>
        <v-tab value="account">{{ $t("minimal_profile_tab_title") }}</v-tab>
      </v-tabs>
    </v-card>

    <!-- <v-slide-x-reverse-transition mode="out-in"> -->
    <v-card
      v-if="activeTab === 'account'"
      class="profile my-2 mt-3"
      :class="
        $isShowTab
          ? 'border-sm rounded-4 p-3 bg-profile-card bg-primary-light'
          : ''
      "
      flat
      variant="p"
    >
      <v-row
        class="justify-center col-gap-md"
        no-gutters
        :class="{ 'align-items-center': !$isShowTab }"
      >
        <v-col cols="4" class="text-center">
          <qrcode-vue
            background="transparent"
            @click="$refs.qrCode.show()"
            style="width: 100%; height: auto"
            :value="
              qrType == 'Telephone' ? profile.telephone : profile.member_id
            "
            render-as="svg"
          />
        </v-col>
        <v-col v-if="$isShowTab" class="d-flex flex-column">
          <div class="color-primary f-xl f-bold">{{ $config.brand_name }}</div>
          <p class="welcome-text">{{ fullName }}</p>
          <p class="member-id">
            {{ $t("member_id") }}: {{ profile.member_id }}
          </p>
          <small class="mt-3 f-sm">
            Member Since :
            {{ $moment(profile.created_time).format("DD/MM/YYYY") }}
          </small>
        </v-col>
        <v-col v-else>
          <p class="welcome-text">WELCOME, {{ fullName }}</p>
          <p class="member-id">MEMBER ID: {{ profile.member_id }}</p>
        </v-col>
      </v-row>
    </v-card>
    <!-- </v-slide-x-reverse-transition> -->

    <!-- <v-slide-x-reverse-transition mode="out-in"> -->
    <v-card v-if="activeTab === 'account'" class="info-section" flat>
      <v-divider class="mb-0"></v-divider>
      <div class="content-between my-4">
        {{ $t("profile_general") }}
        <v-spacer></v-spacer>
        <div class="p-0" v-if="!isEditing" variant="text" @click="editProfile">
          {{ $t("edit") }}
        </div>
        <div
          class="p-0"
          v-if="isEditing"
          variant="text"
          @click="isEditing = false"
        >
          {{ $t("close_edit") }}
        </div>
        <!-- <c-img v-if="!isEditing" class="ml-3" /> -->
        <font-awesome-icon
          v-if="!isEditing"
          icon="chevron-right"
          size="xs"
          class="ml-2"
        ></font-awesome-icon>
        <font-awesome-icon
          v-if="isEditing"
          icon="times"
          size="xs"
          class="ml-2"
          @click="isEditing = false"
        ></font-awesome-icon>
      </div>

      <v-list v-if="!isEditing" class="p-0">
        <template v-for="(value, key) in userInfo" :key="key">
          <v-list-item v-if="userInfo[key].display">
            <v-list-item-title>{{ userInfo[key].display }}</v-list-item-title>
            <v-list-item-subtitle class="color-black">{{
              userInfo[key].value || "-"
            }}</v-list-item-subtitle>
          </v-list-item>
        </template>
      </v-list>

      <v-form v-else>
        <EditProfilePanel @onFinish="onFinish" componentKey />
      </v-form>
      <v-divider class="mb-2" :style="'margin-top:1.5rem'"></v-divider>
      <v-list-item>
        <div class="content-between" @click="$emit('logout')">
          {{ $t("logout") }}
          <div>
            <font-awesome-icon
              icon="chevron-right"
              size="xs"
            ></font-awesome-icon>
          </div>
        </div>
      </v-list-item>
    </v-card>
    <!-- </v-slide-x-reverse-transition> -->

    <!-- <v-slide-x-reverse-transition mode="out-in"> -->
    <PurchaseTab v-if="activeTab === 'purchase'" />
    <!-- </v-slide-x-reverse-transition> -->
  </v-container>

  <v-footer class="footer-tab" v-if="$isShowTab" fill>
    <div class="footer-line"></div>
    <v-tabs grow center-active fixed-tabs v-model="activeTab">
      <v-tab
        value="purchase"
        :class="{ 'active-tab': activeTab == 'purchase' }"
        class="profile-tabs"
      >
        <div
          class="flex-column d-flex justify-center align-center"
          style="row-gap: 0.35rem"
        >
          <c-img
            v-if="$isMinimalCompact"
            :src="require('@/assets/images/shopping-laderach.png')"
            @error="handleImageSrc"
            class="icon-tabs"
            alt="shopping icon tabs"
          />
          <c-img
            v-else
            :src="require('@/assets/images/shopping-tabs.png')"
            @error="handleImageSrc"
            class="icon-tabs"
            alt="shopping icon tabs"
          />

          <div class="f-semi-sm">{{ $t("minimal_transaction_tab_title") }}</div>
        </div>
      </v-tab>
      <v-tab
        value="account"
        :class="{ 'active-tab': activeTab == 'account' }"
        class="profile-tabs"
      >
        <div
          class="flex-column d-flex justify-center align-center"
          style="row-gap: 0.35rem"
        >
          <!-- <font-awesome-icon icon="user"></font-awesome-icon> -->
          <c-img
            v-if="$isMinimalCompact"
            :src="require('@/assets/images/user-laderach.png')"
            @error="handleImageSrc"
            class="icon-tabs"
            alt="user icon tabs"
          />
          <c-img
            v-else
            :src="require('@/assets/images/user-tabs.png')"
            @error="handleImageSrc"
            class="icon-tabs"
            alt="user icon tabs"
          />
          <div class="f-semi-sm">{{ $t("minimal_profile_tab_title") }}</div>
        </div>
      </v-tab>
    </v-tabs>
  </v-footer>
  <ModalQrCode
    ref="qrCode"
    :value="qrType == 'Telephone' ? profile.telephone : profile.member_id"
  />
  <ModalLoading ref="modalLoading" />
</template>

<script>
import { mapState } from "vuex";
import PurchaseTab from "./PurchaseTab.vue";
import ModalLoading from "@/components/other/ModalLoading.vue";
import EditProfilePanel from "@/views/page/editprofile/editProfilePanel";
import ModalQrCode from "@/components/profile/modal/ModalQrCode";

import tabUserIcon from "@/assets/images/user-tabs.png";
export default {
  components: { ModalQrCode, PurchaseTab, ModalLoading, EditProfilePanel },

  data() {
    return {
      activeTab: "account",
      isEditing: false,
      user: {
        ...this.profile
        // full_name:
      },
      qrType: this.$store.state.theme.qr_code,
      profileEdit: {},
      menu: false,
      date: null,
      userInfo: {},
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 300,
        height: 300,
        class: ""
      },
      tabUserIcon
    };
  },
  watch: {
    activeTab(value) {
      this.$router.replace("/profile#" + value);
      this.isEditing = false;
    }
  },
  async created() {
    this.setProfile();
  },
  computed: {
    ...mapState({
      profile: state => state.shortProfile
    }),

    fullName() {
      const middle_name = this.profile.middle_name_th || "";
      return (
        this.profile.first_name_th +
        " " +
        middle_name +
        " " +
        this.profile.last_name_th
      );
    },
    birthday() {
      return this.$moment(this.profileEdit.birthday)
        .subtract(543, "year")
        .format("DD/MM/YYYY");
    }
  },
  methods: {
    async setProfile() {
      const data = await this.$store.dispatch(
        "getProfileFields",
        this.profile.login_type
      );
      const findDisplayName = id => {
        let find = data.detail.result.fields.find(
          el => el.field_profile_type_id == id
        ).field_language;
        if (find.length > 0) return find[0].name;
        return "";
      };

      this.userInfo = {
        first_name: {
          display: findDisplayName(3),
          value: this.profile.first_name_th
        },
        middle_name: {
          display: findDisplayName(31),
          value: this.profile.middle_name
        },
        last_name: {
          display: findDisplayName(5),
          value: this.profile.last_name_th
        },

        email: {
          display: findDisplayName(11),
          value: this.profile.email
        },
        birthday: {
          display: findDisplayName(9),
          value: this.profile.birthday
            ? this.$moment(this.profile.birthday).format("DD/MM/YYYY")
            : ""
        },
        gender: { display: findDisplayName(21), value: this.profile.gender }
      };
    },
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`; // เปลี่ยนเป็น DD/MM/YYYY
    },
    editProfile() {
      this.profileEdit = {
        ...this.profile,
        birthday: new Date(this.$moment(this.profile.birthday).format()),
        full_name: this.profile.first_name_th + " " + this.profile.last_name_th
      };
      this.isEditing = true;
    },

    async onFinish() {
      await this.$store.dispatch("refreshUserProfile");
      await this.setProfile();
      this.isEditing = false;
    }
  }
};
</script>

<style scoped lang="scss">
.account-container {
  margin: auto;
  height: calc(100svh - var(--padding-footer));
  overflow-y: auto; // padding-top: 0;
}

.welcome-text {
  font-size: var(--text-xl);
}

.member-id {
  font-size: var(--text-xl);
}

.v-list-item {
  padding: 0;
  padding-inline: 0 !important;
}

:deep(.v-tab.v-tab--selected) {
  // font-weight: bold !important;
  opacity: 1;
}

.v-tab.v-tab.v-btn {
  // min-width: 150px;
  padding-top: 10px;
}

.logout {
  position: absolute;
  top: 0;
  right: 0;
  height: max-content !important;
  /* font-size: 12px; */
}

::v-deep.logout .v-btn__content {
  font-size: 12px;
}

.v-list-item-subtitle {
  font-size: var(--text-xl);
  color: black !important;
  opacity: 1;
  line-height: unset !important;
}

.footer-tab {
  position: fixed;
  bottom: 0;
  background-color: var(--theme-background-color);
  padding: 5px 15px 40px 15px; // border-top: 1px solid #f7f7f7;
  ::v-deep button {
    border-top: 1px solid;
    border-color: transparent;
  }
  .active-tab {
    border-color: black;
  }
}

.logo-profile {
  width: 100%;
  max-width: 80%;
}

.bg-profile-card {
  background-color: rgb(246 246 236) !important;
}

.footer-line {
  height: 1px;
  left: 0;
  width: 100vw;
  position: absolute;
  top: 0;
  border: thin solid #f7f7f7;
}
.icon-tabs {
  width: 20px;
  height: 20px;
}
</style>
