<template>
  <div v-html="policy"></div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      showModal: false,
      policy: ""
    };
  },
  async created() {
    await this.getPolicy();
  },
  methods: {
    async getPolicy() {
      this.$axios.get(`/api/v1/DSR/privacy_policy`).then(async data => {
        if (data.result === 1) {
          this.policy = data.detail;
        }
      });
    }
  }
};
</script>

<style rel="scss" scoped>
.bg-white {
  background-color: #fff;
  color: #000;
}
.modal-header {
  border: 0 !important;
  padding: 0;
}
.text-text {
  color: #000;
  margin-bottom: 0;
  font-size: var(--text-3xl);
  font-weight: 600;
}
.class-icon-black {
  width: 70px;
}
@media (max-width: 767.98px) {
  .text-text {
    font-size: var(--text-3xl);
  }
}
.ft-14 {
  font-size: var(--text-xl);
}
.ft-underline {
  text-decoration: underline;
}
::v-deep table {
  border-collapse: collapse;
  width: 100%;
}

::v-deep td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

::v-deep tr:nth-child(even) {
  background-color: #dddddd;
}
::v-deep .close {
  font-size: 3.5rem;

  right: 40px;
  top: 10px;
}
</style>
