<template>
  <v-container class="account-container">
    <div
      class="text-center text-uppercase"
      :class="[activeTab === 'account' ? 'mb-8' : 'mb-5']"
    >
      {{
        activeTab === "account"
          ? $t("minimal_profile_tab_title")
          : $t("transaction_header")
      }}
    </div>

    <!-- <v-slide-x-reverse-transition mode="out-in"> -->
    <v-card
      v-if="activeTab === 'account'"
      class="profile my-6 profile-box"
      flat
      variant="p"
    >
      <v-row
        class="justify-center col-gap-md"
        no-gutters
        :class="{ 'align-items-center': !$isShowTab }"
      >
        <v-col cols="4" class="text-center">
          <qrcode-vue
            background="transparent"
            @click="$refs.qrCode.show()"
            style="width: 100%; height: auto"
            :value="
              qrType == 'Telephone' ? profile.telephone : profile.member_id
            "
            render-as="svg"
          />
        </v-col>

        <v-col class="d-flex flex-column">
          <p class="welcome-text">{{ fullName }}</p>
          <p class="member-id">MEMBER ID: {{ profile.member_id }}</p>
          <p class="mt-3">
            Member Since:
            {{ $moment(profile.created_time).format("DD/MM/YYYY") }}
          </p>
        </v-col>
      </v-row>
    </v-card>
    <!-- </v-slide-x-reverse-transition> -->

    <!-- <v-slide-x-reverse-transition mode="out-in"> -->
    <v-card v-if="activeTab === 'account'" class="info-section" flat>
      <div class="profile-box">
        <div class="content-between mb-4">
          <span class="f-lg">{{ $t("profile_general") }}</span>
          <v-spacer></v-spacer>
          <div
            class="p-0 text-underline"
            v-if="!isEditing"
            variant="text"
            @click="editProfile"
          >
            {{ $t("edit") }}
          </div>
          <!-- <div
            class="p-0"
            v-if="isEditing"
            variant="text"
            @click="isEditing = false"
          >
            {{ $t("close_edit") }}
          </div> -->
          <!-- <c-img v-if="!isEditing" class="ml-3" /> -->
          <!-- style="width: 16px; height: 16px" -->
          <c-img
            @click="editProfile"
            v-if="!isEditing"
            :src="require('@/assets/images/pencil.png')"
            @error="handleImageSrc"
            class="pencil-icon ml-2"
            icon="pencil-alt"
          />
          <!-- <font-awesome-icon
            v-if="!isEditing"
            icon="pencil-alt"
            class="ml-2 f-sm"
          ></font-awesome-icon> -->
        </div>

        <v-list v-if="!isEditing" class="p-0">
          <template v-for="(value, key) in userInfo" :key="key">
            <v-list-item v-if="userInfo[key].display">
              <v-list-item-title
                >{{ userInfo[key].display }}
                <span v-if="$isMinimalCompact && userInfo[key].isRequired"
                  >*</span
                ></v-list-item-title
              >
              <v-list-item-subtitle class="color-black"
                >{{ checkIsBirthDay(userInfo[key].value, key) || "-" }}
              </v-list-item-subtitle>
            </v-list-item>
          </template>
        </v-list>

        <v-form v-else>
          <EditProfilePanel @onFinish="onFinish" componentKey />
        </v-form>
      </div>
      <v-list-item class="my-8">
        <div @click="$emit('logout')" class="d-flex align-items-center">
          <!-- <font-awesome-icon
            class="mr-2"
            :icon="['fas', 'sign-out-alt']"
            style="width: 20px; height: 20px"
          /> -->
          <c-img
            :src="require('@/assets/images/logout.png')"
            @error="handleImageSrc"
            class="logout-icon mr-2"
            alt="logout icon"
          />

          <span class="mt-1">{{ $t("logout") }}</span>
        </div>
      </v-list-item>
    </v-card>
    <!-- </v-slide-x-reverse-transition> -->

    <!-- <v-slide-x-reverse-transition mode="out-in"> -->
    <PurchaseTab v-if="activeTab === 'purchase'" />
    <!-- </v-slide-x-reverse-transition> -->
  </v-container>

  <v-footer class="footer-tab" fill>
    <div class="footer-line"></div>
    <v-tabs grow center-active fixed-tabs v-model="activeTab">
      <v-tab
        value="purchase"
        :class="{ 'active-tab': activeTab == 'purchase' }"
        class="profile-tabs"
      >
        <div
          class="flex-column d-flex justify-center align-center"
          style="row-gap: 0.35rem"
        >
          <c-img
            :src="require('@/assets/images/shopping-tabs.png')"
            @error="handleImageSrc"
            class="icon-tabs"
            alt="shopping icon tabs"
          />

          <div class="f-sm">{{ $t("minimal_transaction_tab_title") }}</div>
        </div>
      </v-tab>
      <v-tab
        value="account"
        :class="{ 'active-tab': activeTab == 'account' }"
        class="profile-tabs"
      >
        <div
          class="flex-column d-flex justify-center align-center"
          style="row-gap: 0.35rem"
        >
          <!-- <font-awesome-icon icon="user"></font-awesome-icon> -->
          <c-img
            :src="require('@/assets/images/user-tabs.png')"
            @error="handleImageSrc"
            class="icon-tabs"
            alt="user icon tabs"
          />
          <div class="f-sm">{{ $t("minimal_profile_tab_title") }}</div>
        </div>
      </v-tab>
    </v-tabs>
  </v-footer>
  <ModalQrCode
    ref="qrCode"
    :value="qrType == 'Telephone' ? profile.telephone : profile.member_id"
  />
  <ModalLoading ref="modalLoading" />
</template>

<script>
import { mapState } from "vuex";
import PurchaseTab from "./PurchaseTab.vue";
import ModalLoading from "@/components/other/ModalLoading.vue";
import EditProfilePanel from "@/views/page/editprofile/editProfilePanel";
import ModalQrCode from "@/components/profile/modal/ModalQrCode";

import tabUserIcon from "@/assets/images/user-tabs.png";
export default {
  components: { ModalQrCode, PurchaseTab, ModalLoading, EditProfilePanel },

  data() {
    return {
      activeTab: "account",
      isEditing: false,
      user: {
        ...this.profile
        // full_name:
      },
      qrType: this.$store.state.theme.qr_code,
      profileEdit: {},
      menu: false,
      date: null,
      userInfo: {},
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 300,
        height: 300,
        class: ""
      },
      tabUserIcon
    };
  },
  watch: {
    activeTab(value) {
      this.$router.replace("/profile#" + value);
      this.isEditing = false;
    }
  },
  async created() {
    this.setProfile();
  },
  computed: {
    ...mapState({
      profile: state => state.shortProfile
    }),

    fullName() {
      const middle_name = this.profile.middle_name_th || "";
      return (
        this.profile.first_name_th +
        " " +
        middle_name +
        " " +
        this.profile.last_name_th
      );
    },
    birthday() {
      return this.$moment(this.profileEdit.birthday)
        .subtract(543, "year")
        .format("DD/MM/YYYY");
    }
  },
  methods: {
    checkIsBirthDay(val, key) {
      console.log(val, key);
      if (key == "birthday") {
        console.log(this.$moment(val), this.$moment(val).year());
        let isEmpty = this.$moment(val).year() == "1753" ? false : true;
        console.log(isEmpty);
        return isEmpty ? val : "";
      }
      return val;
    },
    async setProfile() {
      const data = await this.$store.dispatch(
        "getProfileFields",
        this.profile.login_type
      );
      const findDisplayName = (id, key) => {
        let field = data.detail.result.fields.find(
          el => el.field_profile_type_id == id
        );
        let find = field.field_language;

        if (find.length == 0) return "";
        if (key == "required") return field.required;
        if (find.length > 0) return find[0][key];
        return "";
      };

      this.userInfo = {
        first_name: {
          display: findDisplayName(3, "name"),
          isRequired: findDisplayName(3, "required"),
          value: this.profile.first_name_th
        },
        middle_name: {
          display: findDisplayName(31, "name"),
          isRequired: findDisplayName(31, "required"),
          value: this.profile.middle_name
        },
        last_name: {
          display: findDisplayName(5, "name"),
          isRequired: findDisplayName(5, "required"),
          value: this.profile.last_name_th
        },

        email: {
          display: findDisplayName(11, "name"),
          isRequired: findDisplayName(11, "required"),
          value: this.profile.email
        },
        birthday: {
          display: findDisplayName(9, "name"),
          isRequired: findDisplayName(9, "required"),
          value: this.profile.birthday
            ? this.$moment(this.profile.birthday).format("DD/MM/YYYY")
            : ""
        },
        gender: {
          display: findDisplayName(21, "name"),
          isRequired: findDisplayName(21, "required"),
          value: this.profile.gender
        }
      };
    },
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`; // เปลี่ยนเป็น DD/MM/YYYY
    },
    editProfile() {
      this.profileEdit = {
        ...this.profile,
        birthday: new Date(this.$moment(this.profile.birthday).format()),
        full_name: this.profile.first_name_th + " " + this.profile.last_name_th
      };
      this.isEditing = true;
    },

    async onFinish() {
      await this.$store.dispatch("refreshUserProfile");
      await this.setProfile();
      this.isEditing = false;
    }
  }
};
</script>

<style scoped lang="scss">
.account-container {
  margin: auto;
  height: calc(100svh - var(--padding-footer));
  overflow-y: auto; // padding-top: 0;
}

.welcome-text {
  font-size: var(--text-xl);
}

.member-id {
  font-size: var(--text-xl);
}

.v-list-item {
  padding: 0;
  padding-inline: 1px !important;
}

:deep(.v-tab.v-tab--selected) {
  // font-weight: bold !important;
  opacity: 1;
}

.v-tab.v-tab.v-btn {
  // min-width: 150px;
  padding-top: 10px;
}

.logout {
  position: absolute;
  top: 0;
  right: 0;
  height: max-content !important;
  /* font-size: 12px; */
}

::v-deep.logout .v-btn__content {
  font-size: 12px;
}

.v-list-item-subtitle {
  font-size: var(--text-xl);
  color: black !important;
  opacity: 1;
  line-height: unset !important;
}

.footer-tab {
  position: fixed;
  bottom: 0;
  background-color: var(--theme-background-color);
  padding: 0px 15px 40px 15px; // border-top: 1px solid #f7f7f7;
  ::v-deep button {
    border-top: 1px solid;
    border-color: transparent;
  }
  .active-tab {
    border-color: black;
  }
}

.logo-profile {
  width: 100%;
  max-width: 80%;
}

.bg-profile-card {
  background-color: rgb(246 246 236) !important;
}

.footer-line {
  height: 1px;
  left: 0;
  width: 100vw;
  position: absolute;
  top: 0;
  border: thin solid #f7f7f7;
}
.icon-tabs {
  width: 20px;
  height: 20px;
  margin-left: 2px;
}
.profile-box {
  border: 1px solid rgba(230, 231, 234, 1);
  padding: 16px 12px;
  border-radius: 10px;
}
.pencil-icon {
  width: 12.5px;
  height: 12.5px;
}
.logout-icon {
  width: 15px;
  height: 15px;
}
</style>
