<template>
  <v-container class="justify-center fill-height p-0 flex-column">
    <!-- <v-card class="pa-6 text-center" max-width="400" elevation="3"> -->
    <!-- <div> -->
    <img
      :src="$store.state.domain + $store.state.theme.themeLogoLine"
      class="logo-img"
    />
    <div class="f-bold" style="margin-bottom: 15dvh">REGISTRATION SUCCESS</div>
    <div class="w-100 mb-auto" :class="{ 'f-sm': $isMinimalCompact }">
      <div class="w-100">WELCOME</div>
      <!-- <br /> -->
      <div class="f-bold w-100">{{ userInfo.full_name }}</div>

      <qrcode-vue
        :value="
          $store.state.theme.qr_code == 'Telephone'
            ? userInfo.telephone
            : userInfo.member_id
        "
        :size="160"
        class="mt-7"
        render-as="svg"
        background="transparent"
      />
      <div class="mt-2 w-100">
        {{
          $store.state.theme.qr_code == "Telephone"
            ? userInfo.telephone
            : userInfo.member_id
        }}
      </div>
      <div class="mt-4 w-100">Your account has been successfully created.</div>
    </div>

    <div
      class="register-bottom fixed-bottom"
      :class="[$isMinimalCompact ? 'p-3' : 'p-2']"
    >
      <c-btn
        block
        variant="confirm-primary"
        class="mt-4 px-10"
        @click="$emit('close')"
      >
        {{ $t("continue") }}
      </c-btn>
    </div>

    <!-- </div> -->
    <!-- </v-card> -->
  </v-container>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
  components: { QrcodeVue },
  data() {
    return {
      profile: { ...this.$store.state.shortProfile }
    };
  },
  computed: {
    userInfo() {
      const middle_name = this.profile.middle_name_th || "";
      return {
        full_name:
          this.profile.first_name_th +
          " " +
          middle_name +
          "" +
          " " +
          this.profile.last_name_th,
        member_id: this.profile.member_id,
        email: this.profile.email,
        telephone: this.profile.telephone,
        ["date_of_birth (DD/MM/YYYY)"]: this.profile.birthday
          ? this.$moment(this.profile.birthday).format("DD/MM/YYYY")
          : "",
        gender: this.profile.gender
      };
    }
  },
  methods: {
    goToHome() {
      this.$router.push("/home"); // ปรับเส้นทางตามที่ต้องการ
    }
  }
};
</script>

<style scoped>
.fill-height {
  height: 100vh;
  text-align: center;
}

.qrcode {
  border: 2px solid black;
  padding: 10px;
  border-radius: 8px;
}
</style>
