<template>
  <v-card class="purchase-section" flat>
    <template v-if="purchases.length == 0"
      ><div class="mt-3">{{ $t("transaction_empty") }}</div></template
    >
    <template v-else>
      <template v-if="!$isMinimalCompact">
        <v-divider class="mb-3"></v-divider>
        <v-spacer></v-spacer>
        <!-- <v-card-title> -->
        <span data-lang="transaction_title" class="f-bold"
          >{{ $t("transaction_title") }}
        </span>
        ({{ purchases.length }})
        <!-- </v-card-title> -->
        <!-- <v-divider class="my-0"></v-divider> -->
        <!-- <p class="purchase-message p-0 my-6" data-lang="transaction_description">
        {{ $t("transaction_description") }}
      </p>
       -->
      </template>
      <v-divider
        class="mb-0"
        :class="({ 'mt-3': !$isMinimalCompact }, { 'mt-0': $isMinimalCompact })"
      ></v-divider>
      <v-table class="table-purchase-history">
        <thead>
          <tr>
            <th class="text-left" style="width: 35%">
              {{ $t("purchase_id") }}
            </th>
            <th class="text-center">{{ $t("purchase_date") }}</th>
            <th class="text-right">{{ $t("purchase_store") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(purchase, index) in purchases"
            :key="index"
            @click="openDetail(purchase.transaction_id)"
          >
            <td class="text-nowrap">
              <font-awesome-icon
                v-if="!$isMinimalCompact"
                icon="chevron-right"
                size="sm"
                class="mr-2"
              ></font-awesome-icon>
              {{ purchase.invoice_no }}
            </td>
            <td>
              <template v-if="$isMinimalCompact">
                {{ $moment(purchase.transaction_date).format("DD/MM/YYYY") }}
              </template>
              <template v-else>
                {{ $moment(purchase.transaction_date).format("YYYY-MM-DD") }}
              </template>
            </td>
            <td>
              <d class="d-flex align-items-center">
                <span class="line-clamp-1"
                  >{{ purchase.branch_name || "-" }}
                </span>
                <font-awesome-icon
                  v-if="$isMinimalCompact"
                  icon="chevron-right"
                  size="sm"
                  class="ml-2"
                ></font-awesome-icon
              ></d>
            </td>
          </tr>
        </tbody>
      </v-table>
      <div class="content-between f-md f-bold" v-if="!$isMinimalCompact">
        <div>PAGE {{ filter.page }} OF {{ totalPages }}</div>
        <div>
          <font-awesome-icon
            :class="{ 'page-disabled': filter.page == 1 }"
            icon="chevron-left"
            size="sm"
            class="mr-2"
            @click="changePage(false)"
          ></font-awesome-icon>
          <span @click="changePage(true)">NEXT</span>
          <font-awesome-icon
            :class="{ 'page-disabled': filter.page == totalPages }"
            icon="chevron-right"
            size="sm"
            class="ml-2"
            @click="changePage(true)"
          ></font-awesome-icon>
        </div>
      </div>
    </template>
  </v-card>
  <div class="content-between f-md compact-page" v-if="$isMinimalCompact">
    <div>PAGE {{ filter.page }} OF {{ totalPages }}</div>
    <div>
      <font-awesome-icon
        :class="{ 'page-disabled': filter.page == 1 }"
        icon="chevron-left"
        size="sm"
        class="mr-2"
        @click="changePage(false)"
      ></font-awesome-icon>
      <span @click="changePage(true)">NEXT</span>
      <font-awesome-icon
        :class="{ 'page-disabled': filter.page == totalPages }"
        icon="chevron-right"
        size="sm"
        class="ml-2"
        @click="changePage(true)"
      ></font-awesome-icon>
    </div>
  </div>
  <v-dialog
    v-model="showHistory"
    fullscreen
    hide-overlay
    transition="slide-up-down"
    class="purchase-history-dialog"
  >
    <v-card>
      <PurchaseSummary :transactionId="transactionId" @close="hideDetail" />
    </v-card>
  </v-dialog>
</template>

<script>
import PurchaseSummary from "@/views/page/purchaseSummary";
export default {
  components: { PurchaseSummary },
  created() {
    this.getTransaction();
    // this.$logger({
    //   response: {
    //     config: {
    //       url: "/api/v1/redeem/redeem_item",
    //       methods: "POST",
    //       data: JSON.stringify({
    //         payload: {},
    //         branch: ""
    //       })
    //     }
    //   },
    //   action: {
    //     action: "redeem reward error",
    //     id: 3,
    //     note: "er.message"
    //   }
    // });
  },
  computed: {
    // ✅ คำนวณหน้าทั้งหมด

    // ✅ คำนวณข้อมูลที่ต้องแสดงในแต่ละหน้า
    paginatedPurchases() {
      const start = (this.filter.page - 1) * this.filter.pageSize;
      const end = start + this.filter.pageSize;
      return this.purchases.slice(start, end);
    }
  },
  data() {
    return {
      purchases: [],
      showHistory: false,
      transactionId: 0,
      filter: {
        month_ago: 0,
        all_flag: true,
        page: 1,
        pageSize: 5
      },
      totalPages: 1
    };
  },
  methods: {
    async getTransaction() {
      this.emitter.emit("showLoading");
      const result = await this.$axios.post(
        "/api/Transaction/list_customer_transaction",
        this.filter
      );
      this.emitter.emit("hideLoading");
      this.purchases = result.detail.transaction_list;
      this.totalPages = result.detail.allPage;
    },
    openDetail(id) {
      this.showHistory = true;
      this.transactionId = id;
    },
    hideDetail() {
      this.showHistory = false;
      this.transactionId = 0;
    },
    changePage(type) {
      if (type) {
        if (this.filter.page == this.totalPages) return;
        this.filter.page = this.filter.page + 1;
      } else {
        if (this.filter.page == 1) return;
        this.filter.page = this.filter.page - 1;
      }
      this.getTransaction();
    }
  }
};
</script>

<style lang="scss">
.purchase-message {
  padding: 10px;
  font-size: 14px;
  // color: #808080;
}
.table-purchase-history {
  // height: 60vh;
  th {
    font-weight: 600 !important;
    white-space: nowrap;
  }
  td {
    // color: gray;
    // opacity: 0.4;
  }
}
.v-table > .v-table__wrapper > table > tbody > tr > td,
.v-table > .v-table__wrapper > table > tbody > tr > th,
.v-table > .v-table__wrapper > table > thead > tr > td,
.v-table > .v-table__wrapper > table > thead > tr > th,
.v-table > .v-table__wrapper > table > tfoot > tr > td,
.v-table > .v-table__wrapper > table > tfoot > tr > th {
  padding: 0;
  text-align: center;
  // font-weight: 600;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
}

// .v-table > .v-table__wrapper > table > thead > tr > th {
// } /* เปิด: เลื่อนขึ้น */

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
.page-disabled {
  opacity: 0.5;
}
.v-dialog.v-dialog--fullscreen.purchase-history-dialog > .v-overlay__content {
  background-color: var(--theme-background-color);
}
.v-table > .v-table__wrapper > table > tbody > tr > td,
.v-table > .v-table__wrapper > table > thead > tr > th {
  padding: 0 5px !important;
}
.compact-page {
  top: calc(99vh - (var(--padding-footer) + var(--header-height)));
  width: 100%;
  position: absolute;
  left: 0;
  padding: 16px 24px;
  border-top: 1px solid rgba(8, 8, 8, 0.15);
}
</style>
