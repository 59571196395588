<template>
  <div class="content-profile pb-3">
    <v-row class="sub-content" no-gutters>
      <v-col cols="4" style="min-height: 80px">
        <ImageProfile :profile="profile" :userProfile="userProfile" />
      </v-col>
      <!-- <c-btn @click="shareLine">share</c-btn> -->
      <v-col cols="8">
        <div class="width-sub-content">
          <div class="f-sm" v-if="displayMemberLevel">
            {{ profile.display }}
          </div>
          <div>
            <span class="f-xl f-bold">
              {{ profile.first_name_th }}
              {{ profile.last_name_th }}
            </span>
          </div>
          <div class="f-sm">{{ profile.member_id }}</div>
          <div class="qr-icon">
            <font-awesome-icon icon="qrcode" class="" @click="modalQR" />
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="manage-icon">
      <v-menu location="bottom">
        <template v-slot:activator="{ props }">
          <c-btn flat :ripple="false" type="icon" v-bind="props" size="x-small">
            <font-awesome-icon
              :icon="['fas', 'ellipsis-v']"
              class="icon-edit-profile"
            />
          </c-btn>
        </template>

        <v-list>
          <v-list-item
            @click="$router.push('/manage/profile')"
            link-class="px-3"
          >
            <div class="content-between">
              <span data-lang="profile">{{ $t("profile") }}</span>
            </div>
          </v-list-item>
          <template v-if="displayMemberLevel">
            <v-list-item
              @click="$router.push('/member/' + profile.member_level_id)"
              link-class="px-3"
            >
              <div class="content-between">
                <span data-lang="privilege">{{ $t("privilege") }}</span>
              </div>
            </v-list-item>
          </template>

          <v-list-item @click="$router.push('configuration')" link-class="px-3">
            <div class="content-between">
              <span data-lang="setting">{{ $t("setting") }}</span>
            </div>
          </v-list-item>

          <template v-if="displayLogoutButton">
            <v-list-item @click="$emit('logout')" link-class="px-3">
              <div class="content-between">
                <span data-lang="logout">{{ $t("logout") }}</span>
              </div>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </div>
    <div class="content-menu-list rounded-lg p-3" v-if="displayPointAndChannel">
      <v-row>
        <v-col
          cols="12"
          class="point-panel pb-0 text-center"
          style="border-right: unset"
        >
          <div class="f-md text-gray w-100">
            <div data-lang="collect_point">{{ $t("collect_point") }}</div>
          </div>

          <div class="d-flex align-items-center justify-content-center w-100">
            <span class="fa-stack fa-1x icon-background mr-2">
              <c-img
                :src="$store.state.domain + $store.state.theme.point_logo"
                class="img-point"
                v-if="$store.state.theme.point_logo"
              ></c-img>

              <font-awesome-icon v-else icon="star" class="point-icon-color" />
            </span>

            <span class="f-xl f-bold">
              {{ numeralFormat(profile.total_point) }}
            </span>
          </div>

          <div class="page-nav">
            <font-awesome-icon
              @click="checkIsBranchPoint"
              :icon="['fas', 'chevron-right']"
              class="cursor-pointer"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          class="py-0"
          v-if="$config.display_point_expired == 1 || displayConnectChannel"
        >
          <v-divider class="border-opacity-75 my-2"></v-divider>
        </v-col>
        <template v-if="$config.display_point_expired == 1">
          <v-col cols="6" class="pt-0">
            <div class="f-md">
              <div class="text-gray f-sm" data-lang="point_expired">
                {{ $t("point_expired") }}
              </div>

              <div v-if="profile.point_expired_date">
                <span class="f-bold">{{
                  numeralFormat(profile.point_expired || 0)
                }}</span>
                <span data-lang="currency_unit"
                  >&nbsp;{{ $t("currency_unit") }}</span
                >
                ({{ $moment(profile.point_expired_date).format("DD MMM YY") }})
              </div>
              <div v-else>-</div>
            </div>
          </v-col>
        </template>
        <v-col cols="6" class="pt-0" v-else> </v-col>

        <v-col
          v-if="displayConnectChannel"
          cols="6"
          class="pt-0"
          @click="handleMarketPlace()"
        >
          <div class="content-between justify-content-end">
            <div class="f-sm text-nowrap">
              <span class="text-gray" data-lang="earn_channel">
                {{ $t("earn_channel") }}&nbsp;
              </span>

              <u
                class="highlight-color f-sm cursor-pointer text-black"
                @click="handleMarketPlace()"
              >
                <font-awesome-icon :icon="['fas', 'plus']" size="xs" />
                {{ $t("add") }}
              </u>
            </div>
          </div>
          <div class="marketplace-panel justify-content-end">
            <template
              v-for="(market_list, index) of marketPlaceObj.marketplace"
            >
              <div
                class="icon-marketplace"
                v-if="index < 4"
                :key="
                  market_list.shopName +
                  market_list.branchId +
                  '' +
                  market_list.id
                "
              >
                <img
                  :src="
                    market_list.marketplaceUrl ||
                    'https://api-hourglass-admin-dev.dosetech.co/media/NewsImage/231026021009526.jpg'
                  "
                  alt="Marketplace icon"
                  :class="[market_list.status ? '' : 'op-low']"
                />
              </div>
            </template>
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- New Layout -->
    <ModalQRUserNewLayout
      ref="ModalQRUser"
      :profile="profile"
      :userProfile="userProfile"
      class="mx-2"
    />
  </div>
</template>

<script>
// import VueQrcode from "vue-qrcode";
import ImageProfile from "@/components/profile/ImageProfile";
import picture from "@/assets/images/default-image.png";
import ModalQRUserNewLayout from "@/components/profile/modal/ModalQRUserNewLayout";
export default {
  components: {
    ModalQRUserNewLayout,
    ImageProfile
    // VueQrcode
  },
  props: {
    profile: {
      required: true,
      type: Object
    },
    userProfile: {
      required: false,
      type: Object,
      default: () => {}
    },
    marketPlaceObj: {
      required: true
    },
    memberLevel: {
      required: false
    }
  },
  data() {
    return {
      pictureProfile: picture,
      displayLogoutButton:
        this.$store.state.theme.line_display_logout_button == "1"
          ? true
          : false,
      displayPointAndChannel:
        this.$store.state.theme.line_display_point_channel == "1"
          ? true
          : false,
      displayConnectChannel:
        this.$store.state.theme.display_connect_channel == "1" ? true : false,
      layoutDisplay: this.lineLayout,
      displayMemberLevel:
        this.$store.state.theme.line_display_member_level == "1" ? true : false
    };
  },
  updated() {
    const rangeAvg = document.getElementById("range-maintain");
    if (rangeAvg) {
      const avgValue = rangeAvg.dataset.avg;
      let max =
        this.memberLevel.upgrade_tier_id != 0
          ? this.memberLevel.upgrade_baht
          : this.memberLevel.maintain_baht;
      let avgPosition = Number((avgValue * 100) / max);
      rangeAvg.style.left = `${avgPosition > 100 ? 100 : avgPosition}%`;
    }
  },
  created() {
    this.getCompleteProfile();
  },
  methods: {
    async getCompleteProfile() {
      if (this.$store.state.theme.display_profile_complete == 1) {
        const res = await this.$axios(`/api/v1/User/GetCompleteProfile`);
        await this.$store.dispatch("setCompleteProfile", res);

        let bodyStyles = document.body.style;
        if (res.detail.profile == 100) {
          this.$nextTick(() => {
            if (document.getElementById("circle-progress")) {
              document
                .getElementById("circle-progress")
                .classList.remove("progress-bar-circle");
              document
                .getElementById("circle-progress")
                .classList.add("progress-bar-circle-display");
              bodyStyles.setProperty(
                "--count-progress",
                Math.round(res.detail.profile)
              );
            }
          });
        } else {
          this.$nextTick(() => {
            bodyStyles.setProperty(
              "--count-progress",
              Math.round(res.detail.profile)
            );
            document
              .getElementById("circle-progress")
              .setAttribute("count", Math.round(res.detail.profile));
            document.getElementById("circle-progress").style.display = "block";
          });
        }
      }
    },
    dateFormat(value, format) {
      if (!value) return "-";
      if (!format) format = "DD-MM-YYYY";
      return this.$moment(value).format(format);
    },

    modalQR() {
      this.$refs.ModalQRUser.show();
    },
    handleMarketPlace() {
      if (this.marketPlaceObj.marketplace.length == 1) {
        return;
      }
      this.$router.push("/marketplace");
    },
    checkIsBranchPoint() {
      if (this.$store.state.theme.is_center_point == "0")
        return this.$router.push("/branchpoint");
      return this.$router.push("/point");
    }
    // convertTelePhone(val) {
    //   if (!val) return null;

    //   return `${val.substring(0, 3)}-${val.substring(3, 6)}-${val.substring(
    //     6,
    //     10
    //   )}`;
    // }
  }
};
</script>

<style lang="scss" scoped>
@import "@/components/profile/scss/profile.scss";
.manage-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
