import { createWebHistory, createRouter } from "vue-router";
import { auth, guestAuth } from "@/middleware";
const brand = process.env.VUE_APP_BRAND;
import { defineAsyncComponent } from "vue";
import CustomForm from "@/views/page/customForm/detail.vue";
import ProfilePage from "@/views/page/profile";
import EditTelephone from "@/views/page/editprofile/telephone.vue";
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "landing page",
      alias: [""],
      component: () => import("@/views/redirect.vue"),
      meta: {
        // requiresAuth: true,
        title: brand
      }
    },
    {
      path: "/validatetelephone",
      name: "validatetelephone",
      component: () => import("@/views/page/validateTelephone"),
      meta: {
        guestAuth: true,
        title: `${brand}`
      }
    },
    {
      path: "/confirmotp",
      name: "confirmotp",
      component: () => import("@/views/page/validateTelephone/confirmOTP.vue"),
      meta: {
        // requiresAuth: true,
        title: `${brand}`
      }
    },
    {
      path: "/type",
      name: "user type",
      component: () => import("@/views/page/validateTelephone/type.vue"),
      meta: {
        // requiresAuth: true,
        title: `${brand}`
      }
    },
    {
      path: "/coupon",
      name: "coupon-redeem",
      component: () => import("@/views/page/coupon/coupon.vue"),
      meta: {
        requiresAuth: true,
        title: brand
      }
    },
    {
      path: "/coupon/:couponUser/:couponKey",
      name: "coupon-redeem-detail",
      component: () => import("@/views/page/coupon/detail/Detail.vue"),
      meta: {
        requiresAuth: true,
        title: brand
      }
    },
    {
      path: "/couponhistory/:id",
      name: "coupon-history-detail",
      component: () =>
        import("@/views/page/privilege/detail/CouponHistory.vue"),
      meta: {
        requiresAuth: true,
        title: brand
      }
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/views/page/register"),
      meta: { title: brand }
    },

    {
      path: "/edit-profile-required",
      name: "edit profile required fields",
      component: () => import("@/views/page/completeProfile"),
      meta: {
        requiresAuth: true,
        title: brand
      }
    },
    {
      path: "/form/:id",
      name: "Custom Object",
      component: () => import("@/views/page/customForm"),
      meta: {
        requiresAuth: true,
        title: brand
      }
    },
    {
      path: "/preview-form/:id",
      name: "Custom Object Preview Form",
      component: CustomForm,
      meta: {
        requiresAuth: true,
        title: brand
      }
    },
    {
      path: "/profile",
      name: "profile",
      component: ProfilePage,
      meta: {
        requiresAuth: true,
        title: brand
      }
    },
    {
      path: "/manage",
      name: "manage profile",
      component: () => import("@/views/page/editprofile"),
      meta: {
        requiresAuth: true,
        title: brand
      }
    },
    {
      path: "/manage/profile",
      name: "editprofile",
      component: defineAsyncComponent(() =>
        import("@/views/page/editprofile/editProfilePanel.vue")
      ),
      meta: {
        requiresAuth: true,
        title: brand
      }
    },

    {
      path: "/privilege",
      name: "privilege",
      component: () => import("@/views/page/privilege"),
      meta: {
        requiresAuth: true,
        title: brand
      }
    },
    {
      path: "/privilege/:privilegeId",
      name: "privilege-detail",
      component: () => import("@/views/page/privilege/detail/Detail.vue")
    },
    {
      path: "/privilegehistory/:id",
      name: "privilege-history-detail",
      component: () => import("@/views/page/privilege/detail/DetailHistory.vue")
    },
    {
      path: "/privilege/set/:id",
      name: "privilege-set-detail",
      component: () => import("@/views/page/privilege/detail/PrivilegeSet.vue")
    },

    {
      path: "/point",
      name: "point",
      component: () => import("@/views/page/point"),
      meta: {
        requiresAuth: true,
        title: brand
      }
    },
    {
      path: "/branchPoint",
      name: "Branch Point",
      component: () => import("@/views/page/point/branch.vue"),
      meta: {
        requiresAuth: true,
        title: brand
      }
    },
    {
      path: "/point/log/:mode",
      name: "Branch Point Log",
      component: () => import("@/views/page/point/log.vue"),
      meta: {
        requiresAuth: true,
        title: brand
      }
    },

    {
      path: "/events",
      name: "events",
      component: () => import("@/views/page/events"),
      meta: { title: brand, requiresAuth: true }
    },
    {
      path: "/events/landing",
      name: "landing",
      component: () => import("@/views/page/events/landing"),
      meta: { title: brand, requiresAuth: true }
    },

    {
      path: "/review",
      name: "review",
      component: () => import("@/views/page/review"),
      meta: { title: brand }
    },
    {
      path: "/manage/profile/edit/:type",
      name: "editprofile-telephone",
      component: EditTelephone,
      meta: {
        title: `${brand}`
      }
    },
    {
      path: "/confirmotpedit",
      name: "confirmotpedit",
      component: () => import("@/views/page/editprofile/confirmotpedit.vue"),
      meta: {
        title: `${brand}`
      }
    },
    {
      path: "/news",
      name: "news",
      component: () => import("@/views/page/news")
    },
    {
      path: "/news/:newsId",
      name: "news-detail",
      component: () => import("@/views/page/news/detail/Detail.vue")
    },
    {
      path: "/reward",
      name: "reward",
      component: () => import("@/views/page/reward"),
      meta: {
        anonymous: true,
        title: `Reward`
      }
    },
    {
      path: "/reward/:rewardId",
      name: "Reward Detail",
      exact: true,
      component: () => import("@/views/page/reward/detail/Detail.vue"),
      meta: {
        anonymous: true,
        title: `Reward`
      }
    },
    {
      path: "/reward/privilege/:rewardId",
      name: "Reward Privilege Detail",
      exact: true,
      component: () =>
        import("@/views/page/reward/detail/DetailRewardPrivilege.vue"),
      meta: {
        requiresAuth: true,
        title: `Reward`
      }
    },
    {
      path: "/rewardhistory",
      name: "reward-history",
      component: () => import("@/views/page/reward/detail/History.vue")
    },
    {
      path: "/rewardhistory/:id",
      name: "reward-history-detail",
      exact: true,
      component: () => import("@/views/page/reward/detail/DetailHistory.vue")
    },
    {
      path: "/editaddress/:id",
      name: "reward-edit-address",
      component: () =>
        import("@/views/page/reward/detail/DetailEditAddress.vue"),
      meta: {
        requiresAuth: true,
        title: `${brand}`
      }
    },
    {
      path: "/form",
      name: "campaign form",
      component: () => import("@/views/page/campaignform"),
      meta: {
        requiresAuth: true,
        title: `Form`
      }
    },
    {
      path: "/setting",
      name: "Setting PDPA",
      component: () => import("@/views/page/settingpdpa"),
      meta: {
        requiresAuth: true,
        title: `Setting`
      }
    },
    {
      path: "/policy",
      name: "policy",
      component: () => import("@/views/page/policy"),
      meta: {
        requiresAuth: true,
        title: `Policy`
      }
    },
    {
      path: "/terms",
      name: "terms",
      component: () => import("@/views/page/terms"),
      meta: {
        requiresAuth: true,
        title: `Terms`
      }
    },
    {
      path: "/marketplace",
      name: "Marketplace",
      component: () => import("@/views/page/marketplace"),
      meta: {
        requiresAuth: true,
        title: `Marketplace`
      }
    },
    {
      path: "/connectMarketplace",
      name: "Marketplace Connect",
      component: () => import("@/views/page/marketplace/connectMarketplace"),
      meta: {
        requiresAuth: true,
        title: `Marketplace`
      }
    },
    {
      path: "/game",
      name: "Game",
      component: () => import("@/views/page/game"),
      meta: {
        requiresAuth: true,
        title: `Redirect Game`
      }
    },
    {
      path: "/stampcard",
      name: "Stampcard",
      component: () => import("@/views/page/stampcard"),
      meta: {
        requiresAuth: true,
        title: `Stamp Card`
      }
    },
    {
      path: "/stampcard/detail/:id",
      name: "Stampcard Detail",
      exact: true,
      component: () => import("@/views/page/stampcard/detail"),
      meta: {
        requiresAuth: true,
        title: `Stamp Card`
      }
    },
    {
      path: "/booking",
      name: "booking",
      component: () => import("@/views/page/booking"),
      meta: {
        requiresAuth: true,
        title: `Booking`
      }
    },
    {
      path: "/booking/detail/:id",
      name: "booking Detail",
      exact: true,
      component: () => import("@/views/page/booking/detail"),
      meta: {
        requiresAuth: true,
        title: `Booking Detail`
      }
    },
    {
      path: "/member/:id",
      name: "Member Description",
      component: () => import("@/views/page/member"),
      meta: {
        requiresAuth: true,
        title: `${brand}`
      }
    },
    {
      path: "/kis",
      name: "KIS Transaction",
      component: () => import("@/views/page/kis"),
      meta: {
        // requiresAuth: true,
        title: `KIS Transaction`
      }
    },
    {
      path: "/invite/:id",
      name: "Friend Get Friend Invite",
      exact: false,
      component: () => import("@/views/page/FriendGetFriend"),
      meta: {
        // requiresAuth: true,
        title: `Friend Get Friend`
      }
    },
    {
      path: "/invite/:id/list",
      name: "Friend Get Friend Invite List",
      exact: false,
      component: () => import("@/views/page/FriendGetFriend/inviteList.vue"),
      meta: {
        requiresAuth: true,
        title: `Friend Get Friend`
      }
    },
    {
      path: "/reward-transaction",
      name: "Reward From Transaction code List",
      exact: true,
      component: () => import("@/views/page/transactionReward"),
      meta: {
        requiresAuth: true,
        title: `Reward From Transaction code`
      }
    },
    {
      path: "/reward-transaction/detail/:id",
      name: "Reward From Transaction code Detail",
      exact: true,
      component: () => import("@/views/page/transactionReward/detail"),
      meta: {
        requiresAuth: true,
        title: `Reward From Transaction code`
      }
    },
    {
      path: "/reward-transaction/detail/:id/coupon",
      name: "Coupon From Transaction code Detail",
      component: () => import("@/views/page/transactionReward/couponDetail"),
      meta: {
        requiresAuth: true,
        title: `Reward From Transaction code Code`
      }
    },
    {
      path: "/reward-transaction/detail/:id/editaddress",
      name: "EditAddress Transaction code Detail",
      component: () => import("@/views/page/transactionReward/editAddress"),
      meta: {
        requiresAuth: true,
        title: `Random Reward Edit Address`
      }
    },
    {
      path: "/reward-transaction/detail/:id/addressdetail",
      name: "Address Transaction code Detail",
      component: () => import("@/views/page/transactionReward/detailAddress"),
      meta: {
        requiresAuth: true,
        title: `Random Reward Address Detail`
      }
    },
    {
      path: "/transaction",
      name: "Transaction",
      component: () => import("@/views/page/transaction"),
      meta: {
        requiresAuth: true,
        title: `Transaction`
      }
    },
    {
      path: "/configuration/language",
      name: "Language",
      component: () => import("@/views/page/setting/language"),
      meta: {
        requiresAuth: true,
        title: `Language`
      }
    },
    {
      path: "/configuration",
      name: "configuration",
      component: () => import("@/views/page/setting"),
      meta: {
        requiresAuth: true,
        title: `Setting`
      }
    },
    {
      path: "/transaction/detail/:id",
      name: "Transaction Detail",
      component: () => import("@/views/page/transaction/detail"),
      meta: {
        requiresAuth: true,
        title: `Transaction`
      }
    },
    {
      path: "/receipt/upload",
      name: "receipt upload",
      component: () => import("@/views/page/receiptScanner/upload"),
      meta: { requiresAuth: true, title: `Receipt Upload` }
    },
    {
      path: "/mission",
      name: "Mission Page",
      component: () => import("@/views/page/mission"),
      meta: {
        requiresAuth: true,
        title: `Mission`
      }
    },
    {
      path: "/mission/:type/:id",
      name: "Mission Detail",
      component: () => import("@/views/page/mission/detail.vue"),
      meta: {
        requiresAuth: true,
        title: `Mission`
      }
    },
    {
      path: "/mission-info/:id/:m_id",
      name: "Mission Detail Reward Info",
      component: () => import("@/views/page/mission/info.vue"),
      meta: {
        requiresAuth: true,
        title: `Mission`
      }
    },
    {
      path: "/mission-info/address/:id/:m_id",
      name: "Mission Detail Address Edit",
      component: () => import("@/views/page/mission/editAddress.vue"),
      meta: {
        requiresAuth: true,
        title: `Mission`
      }
    },
    {
      path: "/mission-info/reward/:id/:m_id",
      name: "Mission Detail reward detail Info",
      component: () => import("@/views/page/mission/rewardDetail.vue"),
      meta: {
        requiresAuth: true,
        title: `Mission`
      }
    },

    {
      path: "/receipt",
      name: "Receipt",
      component: () => import("@/views/page/receiptScanner"),
      meta: { requiresAuth: true, title: `receipt` }
    },

    {
      path: "/:redeemId/:transactionCode",
      name: "redirect transaction",
      exact: true,
      component: () => import("@/views/page/redirectTransaction"),
      meta: {
        requiresAuth: true,
        title: `Transaction Code`
      }
    },
    {
      path: "/:pathName/:redeemId/:transactionCode",
      name: "redirect transaction From Link",
      exact: true,
      component: () => import("@/views/page/redirectTransaction"),
      meta: {
        requiresAuth: true,
        title: `Transaction Code`
      }
      // redirect: to => {
      //   return {
      //     path: "/transaction",
      //     query: { code: to.params.transaction_code }
      //   };
      // }
    },
    {
      path: "/force-logout",
      name: "redirect transaction From Link",
      exact: true,
      component: () => import("@/views/page/forceLogout.vue"),
      meta: {
        requiresAuth: true,
        title: `Redirect-Logout`
      }
      // redirect: to => {
      //   return {
      //     path: "/transaction",
      //     query: { code: to.params.transaction_code }
      //   };
      // }
    },
    {
      path: "/:catchAll(.*)",
      name: "Page404",
      component: () => import("@/views/page/404")
    }
  ]
});
router.beforeEach(async (to, from, next) => {
  const context = {
    from,
    next,
    router,
    to
  };

  if (to.meta.requiresAuth || to.meta.guestAuth) {
    const middleware = to.meta.requiresAuth ? auth : guestAuth;
    return await middleware({ ...context });
  }
  next();
});
export default router;
