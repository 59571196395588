<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <template v-else>
      <ProfileEditFormPanelNewTheme
        v-if="lineLayout.includes('Minimal')"
        :componentKey="componentKey"
        @onFinish="$emit('onFinish')"
        :form="form"
        :consentShow="consentShow"
        :customField="customField"
        :dynamicField="dynamicField"
        :questionList="questionList"
        :socialField="socialField"
        :v$="v$"
        :isEdit="true"
        :saveIDCard="saveIDCard"
      />
      <ProfileEditFormPanel
        v-else
        :componentKey="componentKey"
        @onFinish="$emit('onFinish')"
        :form="form"
        :consentShow="consentShow"
        :customField="customField"
        :dynamicField="dynamicField"
        :questionList="questionList"
        :socialField="socialField"
        :v$="v$"
        :isEdit="true"
        :saveIDCard="saveIDCard"
      />
    </template>
  </div>
</template>

<script>
import ProfileEditFormPanel from "@/components/profile/edit/ProfileEditFormPanel";
import ProfileEditFormPanelNewTheme from "@/components/profile/edit/ProfileEditFormPanelNewTheme.vue";
import OtherLoading from "@/components/other/OtherLoading";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  minLength,
  maxLength,
  numeric,
  requiredIf,
  helpers
} from "@vuelidate/validators";
import { fieldInForm2, profile_fields2 } from "@/assets/json/fieldProfile.js";
const moment = require("moment");
const Script_checkID = (id, c) => {
  if (c.is_company == 1) return true;
  let i = 0,
    sum = 0;

  if (id.substring(0, 1) == 0) return false;
  for (i = 0, sum = 0; i < 12; i++) sum += parseFloat(id.charAt(i)) * (13 - i);
  if ((11 - (sum % 11)) % 10 != parseFloat(id.charAt(12))) return false;
  return true;
};
export default {
  components: {
    ProfileEditFormPanel,
    OtherLoading,
    ProfileEditFormPanelNewTheme
  },
  props: {
    componentKey: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    for (const item of this.customField) {
      const key = this.checkIsProfile(item.field_profile_type_id);

      if (key) {
        if (item.required) {
          if (item.field_profile_type_id == 11) {
            profile_fields2[key] = {
              required,
              email,
              customEmail: this.customEmailValidator
            };
          } else if (item.field_profile_type_id == 10)
            profile_fields2[key] = {
              required,
              maxLength: maxLength(10),
              minLength: minLength(10),
              numeric
            };
          else if (item.field_profile_type_id == 9) {
            profile_fields2["birthday_day"] = {
              required
            };
            profile_fields2["birthday_month"] = {
              required
            };
            profile_fields2["birthday_year"] = {
              required
            };
          } else if (item.field_profile_type_id == 1)
            profile_fields2[key] = {
              required,
              Script_checkID,
              maxLength: maxLength(13),
              minLength: minLength(13),
              numeric
            };
          else if (item.field_profile_type_id == 29) {
            profile_fields2[key] = {
              required
            };
            profile_fields2.company_branch = {
              required: requiredIf(function () {
                return this.form[key] == "สาขา";
              })
            };
            profile_fields2.company_branch_no = {
              required: requiredIf(function () {
                return this.form[key] == "สาขา";
              })
            };
          } else profile_fields2[key] = { required };
        } else {
          if (item.field_profile_type_id == 11) {
            profile_fields2[key] = { email };
          } else profile_fields2[key] = {};
        }
      }
    }
    const checkValue = (val1, v, a) => {
      if (v.required)
        if (!val1) return true;
        else return false;
    };
    return {
      form: profile_fields2,
      dynamicField: {
        $each: helpers.forEach({
          user_answer: {
            required: requiredIf(checkValue)
          }
        })
      },
      socialField: {
        $each: helpers.forEach({
          user_answer: {
            required: requiredIf(checkValue)
          }
        })
      }
    };
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "ข้อมูลส่วนตัว",
          to: "/profile"
        }
      ],
      form: {
        picture: null,
        profile: null,
        total_point: null,
        is_consent: 0,
        ...profile_fields2
      },

      isLoading: true,
      consentShow: false,
      customField: [],
      dynamicField: [],
      socialField: [],
      questionList: [],
      saveIDCard: ""
    };
  },
  async created() {
    this.getDataProfile();
  },
  methods: {
    customEmailValidator(value) {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return emailRegex.test(value);
    },
    checkIsProfile(val) {
      const findField = fieldInForm2.find(el => el.id == val);

      return findField ? findField.key : false;
    },
    async getCustomField(type) {
      let url = "/api/v1/user/GetUserRegisterCustomFieldList";
      if (type == 3) url = "/api/v1/user/GetUserRegisterCustomEmailFieldList";
      const customField = await this.$axios(url);

      this.form.custom_field = customField.detail;
    },
    async getSocialField() {
      const socialField = await this.$axios(
        "/api/v1/user/GetUserSocialMediaList"
      );
      this.$store.commit("setSocialUser", socialField.detail);

      this.form.social = socialField.detail;
    },
    async getDataProfile() {
      this.isLoading = true;
      var object = await this.$store.dispatch("getUserProfile");

      this.saveIDCard = object.id_card;
      object.country = object.country ? object.country : "ประเทศไทย";

      this.form = { ...object };
      let type = this.form.is_company == 0 ? this.form.login_type : 2;
      if (this.form.is_company == 0 && this.form.login_type == 2) type = 3;
      await this.getSocialField();
      const res = await Promise.all([
        this.getCustomField(type),
        this.getFieldRegister(type)
      ]);

      this.consentShow = this.form.is_consent === 1 ? true : false;
      // await ;
      this.isLoading = false;
    },
    async getFieldRegister(type = 1) {
      const data = await this.$store.dispatch("getProfileFields", type);
      if (data.result === 1) {
        this.customField = data.detail.result.fields.filter(
          el => el.show_on_edit_profile == 1
        );

        for (const checkForm of this.customField) {
          if (checkForm.field_profile_type_id == 9 && this.yearFormat == 1) {
            if (this.form.birthday_year)
              this.form.birthday_year = moment(
                `${this.form.birthday_day}/${this.form.birthday_month}/${this.form.birthday_year}`,
                "DD/MM/YYYY"
              )
                .add(543, "y")
                .year();
          }
          if (
            checkForm.field_profile_type_id == 18 &&
            this.form.country == "ประเทศไทย"
          )
            checkForm.field_choices = await this.$store.dispatch("getProvince");
          let $disable = data.detail.result.fields.find(
            el => el.field_dependent == checkForm.id
          );
          if ($disable && checkForm.field_type_id == 15) {
            checkForm.field_choices = [];
          }
        }

        for (const field of data.detail.result.custom_field) {
          let form = this.form.custom_field
            ? this.form.custom_field.find(el => el.id == field.id)
            : null;

          if (form) {
            let value = form.user_answer.map(el => el.answer);
            if (value.length == 0)
              value = field.field_choices
                .filter(el => el.isDefault == 1)
                .map(el => el.name);
            if (field.field_type_id == 2) {
              field.user_answer = value;
            } else {
              field.user_answer = value.toString();
            }
          }
        }

        for (const element of data.detail.result.fields) {
          // console.log(element);
        }
        this.dynamicField = data.detail.result.custom_field.filter(
          el => el.show_on_edit_profile == 1
        );
        this.socialField = data.detail.result.social_field
          .filter(el => el.show_on_edit_profile == 1)
          .map(el => {
            let answer = this.form.social.find(
              form => form.social_media_type_id == el.id
            );

            return {
              ...el,
              answer_id: answer ? answer.id : 0,
              field_type_id: 1,
              user_answer: answer ? answer.value : ""
            };
          })
          .sort((a, b) => a.sort_order - b.sort_order);
      }

      // this.isLoading = false;
    },
    checkField(val) {
      return val == "N/A" || val == null || val == undefined;
    },

    async handleChange(val, field) {
      let payload = await field.field_choices.find(el => el.name == val);

      if (payload)
        if (payload.denpendent.length > 0) {
          let $set = this.customField.findIndex(
            el => el.id == field.field_dependent
          );
          this.customField[$set].name = payload.description || payload.name;
          this.customField[$set].field_choices = await payload.denpendent.map(
            el => {
              return {
                ...el,
                name: el.choice_dependent_name,
                sort_order: 0,
                description: el.name,
                denpendent: el.level3,
                is_display: 1
              };
            }
          );
        }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
