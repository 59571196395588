<template>
  <template v-if="isLoading">
    <OtherLoading variant="dark" />
  </template>
  <template v-else>
    <div class="profile-container">
      <ProfileCompact
        v-if="$isMinimalCompact"
        :profile="profile"
        :userProfile="lineProfile"
        @logout="logout"
        :marketPlaceObj="marketPlaceObject"
        :memberLevel="memberLevel"
      />
      <ProfileMinimal
        v-else
        :profile="profile"
        :userProfile="lineProfile"
        @logout="logout"
        :marketPlaceObj="marketPlaceObject"
        :memberLevel="memberLevel"
      />
    </div>
  </template>
  <ModalLoading ref="modalLoading" />

  <ModalConfirm
    ref="ModalConfirm"
    :text="$t('confirm_logout')"
    @handler="unpairfunc"
  />
</template>

<script>
import ProfileMinimal from "./ProfileLayoutMinimal.vue";
import ProfileCompact from "./ProfileLayoutCompact.vue";

import ModalConfirm from "@/components/alert-modal/ModalConfirm";
import OtherLoading from "@/components/other/OtherLoading";

import ModalLoading from "@/components/other/ModalLoading.vue";

export default {
  components: {
    ProfileMinimal,
    ProfileCompact,

    ModalLoading,
    ModalConfirm,
    OtherLoading
  },
  mounted() {},
  data() {
    return {
      MenuList: [],
      profile: { ...this.$store.state.shortProfile },
      lineProfile: this.$config.isLoginLine
        ? this.$cookies.get("user_profile_token")
        : {},
      isLoading: true,
      filter: {
        SocialProvider: this.$config.socialProvider,
        SocialId: ""
      },
      privilegeList: [],
      notFoundData: false,
      rewardList: [],

      // newsList: [],
      bannerList: [],
      FGFList: [],
      filterBanner: {
        level_id: 1
      },
      marketPlaceObject: [],
      bannerFriend: [],
      customFormList: [],
      bannerIndex: 0,
      memberLevel: { upgrade_baht: 9999, maintain_baht: 9999 },
      filterNews: {
        page: 1,
        take: 10
      }
    };
  },

  async created() {
    this.LoginExternal();
    // console.log(.);
  },
  methods: {
    async getBannerGetFriend() {
      const res = await this.$axios(`/api/v1/FriendGetFriend/get`);

      this.bannerFriend = res.detail;
    },

    async getBanner() {
      await this.$axios
        .get(`/api/v1/banner/campaign_form`)
        .then(async data => {
          if (data.result == 1) {
            if (data.detail.totalCount > 0) {
              this.bannerList = data.detail.detail;
            }
          }
        })
        .catch(error => {
          console.log("getBanner", error.message);
        });
    },
    async getCouponList() {
      await this.$axios.get(`/api/v1/privilege/coupon`).then(async data => {
        if (data.result == 1) {
          if (data.detail.length > 0) {
            this.notFoundData = false;
            this.privilegeList = data.detail;
          } else {
            this.notFoundData = true;
          }
        }
      });
    },
    async getRewardList() {
      await this.$axios.get(`/api/v1/redeem/get_redeem_item_list`).then(
        async data => {
          // this.isLoading = false;
          if (data.result == 1) {
            this.rewardList = data.detail;
          }
        },
        error => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            console.log(error);
          }
        }
      );
    },

    async unpairfunc() {
      try {
        this.$refs.modalLoading.show();
        const data = await this.$store.dispatch("unpairUser");
        if (data.result == 1) {
          this.$refs.modalLoading.hide();
          this.$router.replace("/validatetelephone");
        } else window.alert("Internal Server Error.");
      } catch (error) {
        setTimeout(() => {
          this.$refs.modalLoading.hide();
          this.$router.replace("/validatetelephone");
        }, 500);
      }
    },
    logout() {
      this.$refs.ModalConfirm.show();
    },
    async LoginExternal() {
      // await this.memberDesc(this.profile.member_level_id);
      // this.getBanner();
      // this.getMenuList();
      // this.getBannerGetFriend();
      // this.getMarketPlace();

      this.isLoading = false;
    },

    async getMarketPlace() {
      try {
        const data = await this.$axios(`/api/v1/User/get/marketplace`);
        this.marketPlaceObject = data.detail;
      } catch (error) {
        console.log("getMarketPlace", error.message);
      }
    },

    async memberDesc(id) {
      this.memberLevel = this.$store.state.shortProfile.member_list.find(
        el => el.is_default == 1
      );
    },
    async getNewsList() {
      await this.$axios
        .post(`/api/v1/MonthlyPrivilege/list`, this.filterNews)
        .then(async data => {
          if (data.result == 1) {
            this.newsList = data.detail.detail;
            this.isLoading = false;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-container {
  position: relative;
  // background: white;
  // margin: 1rem;
  // border-radius: 5px;
}
.menulist-container {
  background: white;
  border-radius: 5px;
  margin: 1rem;

  // padding-bottom: 100px;
}

.line-text-pf {
  border-left: 6px solid black;
}
.btn-unpair {
  background-color: transparent;
  border: none;
  color: #bebebe;
}
.w-90 {
  width: 90%;
}
.banner-friend {
  img {
    border-radius: 5px;
    aspect-ratio: 16/9;
  }
}
</style>
