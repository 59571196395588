<template>
  <div class="my-auto">
    <div
      :class="
        $store.state.theme.display_profile_complete == '1'
          ? 'progress-bar-circle shadow-sm'
          : ''
      "
      :id="
        $store.state.theme.display_profile_complete == '1' && 'circle-progress'
      "
    >
      <div
        @click="modalQR"
        v-if="userProfile.pictureUrl"
        class="profile-img"
        v-bind:style="{
          backgroundImage: 'url(' + userProfile.pictureUrl + ')'
        }"
      ></div>
      <div
        @click="modalQR"
        v-else-if="profile.picture"
        class="profile-img"
        v-bind:style="{
          backgroundImage: 'url(' + profile.picture + ')'
        }"
      ></div>
      <div @click="modalQR" v-else class="profile-img default">
        <div class="empty-picture">{{ profile.first_name_th[0] }}</div>
      </div>
    </div>
  </div>
  <ModalQRUser
    ref="ModalQRUser"
    :profile="profile"
    :userProfile="userProfile"
    class="mx-2"
  />
</template>

<script>
import ModalQRUser from "@/components/profile/modal/ModalQRUser";
export default {
  components: { ModalQRUser },
  props: {
    userProfile: { required: true, type: Object },
    profile: { required: true, type: Object }
  },
  methods: {
    modalQR() {
      this.$refs.ModalQRUser.show();
    }
  }
};
</script>

<style>
.profile-img {
  position: absolute;
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid black;
  border-radius: 50%;
  left: 4px;
  top: 4px;
}
.profile-img.default {
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  left: 0px;
  top: 0px;
}
.empty-picture {
  position: absolute;
  top: 55%;
  left: 50%;
  font-size: 42px;
  transform: translate(-50%, -55%);
}
</style>
