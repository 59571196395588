<template>
  <div class="content-edit-profile" :class="{ 'px-3': !componentKey }">
    <v-row
      no-gutters
      :style="lineLayout.includes('Minimal') ? '' : 'row-gap: 10px'"
      v-if="!isAddOptionalTelephone"
    >
      <template
        v-for="(profile, index) in fields"
        :key="'profile-field' + index"
      >
        <v-col
          cols="12"
          v-if="profile.id != 'delete' && profile.is_address_display != false"
          :class="{ optional: profile.field_profile_type_id == 22 }"
        >
          <div
            :set="
              (field = v$.form[checkIsProfile(profile.field_profile_type_id)])
            "
            :id="'field-' + profile.profile_name"
          >
            <div
              v-if="
                profile.field_type_id == 1 &&
                (profile.field_profile_type_id == 10 ||
                  (profile.field_profile_type_id == 11 && loginType == 2)) &&
                isEdit
              "
            >
              <div class="content-between">
                <label class="label-text mb-0">
                  {{ changeName(profile) }}
                  <span class="remark text-danger">*</span>
                </label>
                <div @click="$emit('addOptionalTelephone')">
                  <small v-if="profile.field_profile_type_id == 10">
                    <u data-lang="optional_telephone">
                      {{ $t("optional_telephone") }}
                    </u>
                  </small>
                </div>
              </div>
              <v-row
                class="box-tel"
                no-gutters
                style="padding: 5px 10px"
                @click="editPhone(profile.field_profile_type_id)"
              >
                <v-col class="p-0">{{ form.telephone }}</v-col>
                <v-col class="text-right pr-0">
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </v-col>
              </v-row>
            </div>
            <div
              v-else-if="
                profile.field_type_id == 1 &&
                profile.field_profile_type_id != 22
              "
            >
              <InputText
                v-if="!isLoading"
                :textFloat="changeName(profile)"
                :placeholder="changeName(profile)"
                class="margin-inputf-regular"
                :type="checkFieldConditon(profile)"
                :name="profile.profile_name"
                size="lg"
                v-model:value="
                  form[checkIsProfile(profile.field_profile_type_id)]
                "
                :maxlength="checkMaxMin(profile.field_conditions, 2)"
                :min="checkMaxMin(profile.field_conditions, 3)"
                :max="checkMaxMin(profile.field_conditions, 4)"
                :isRequired="profile.required"
                :isValidate="field.$error"
                :v="field"
                :disabled="disabledCondition(profile)"
                @onDataChange="isMaxMin"
                @onkeypress="isNumber"
                :index="index"
              />
            </div>
            <div v-else-if="profile.field_type_id == 3">
              <div class="input-custom">
                <InputRadio
                  :title="profile.name"
                  :name="profile.profile_name"
                  :id="`${profile.id}`"
                  :options="profile.field_choices"
                  value-field="name"
                  text-field="display_name"
                  v-model:value="
                    form[checkIsProfile(profile.field_profile_type_id)]
                  "
                  :v="field"
                  :validateText="profile.validate_text"
                  :isValidate="field.$error"
                  :isRequired="profile.required"
                ></InputRadio>
                <template
                  v-if="
                    form[checkIsProfile(profile.field_profile_type_id)] ==
                    'สาขา'
                  "
                >
                  <InputText
                    v-if="!isLoading"
                    textFloat=""
                    placeholder="รหัสสาขา"
                    class="margin-input f-regular"
                    :type="checkFieldConditon(profile)"
                    name="name-branch"
                    size="lg"
                    v-model:value="profile.company_branch_no"
                    :isRequired="profile.required"
                    :isValidate="v$.form.company_branch_no.$error"
                    :v="v$.form.company_branch_no"
                    @input="val => setValue(val, 'company_branch_no')"
                  />
                  <InputText
                    v-if="!isLoading"
                    textFloat=""
                    placeholder="ชื่อสาขา"
                    class="margin-input f-regular mt-2"
                    :type="checkFieldConditon(profile)"
                    name="name-branch"
                    size="lg"
                    v-model:value="profile.company_branch"
                    :isRequired="profile.required"
                    :isValidate="v$.form.company_branch.$error"
                    :v="v$.form.company_branch"
                    @input="val => setValue(val, 'company_branch')"
                  />
                </template>
              </div>
            </div>
            <div v-else-if="profile.field_type_id == 16" class="margin-input">
              <v-row no-gutters>
                <!-- <v-col cols="12" class="pb-0" v-if="!componentKey">
                  <label class="mb-0">
                    {{ changeName(profile) }}
                    <span v-if="profile.required" class="text-danger">*</span>
                  </label>
                </v-col> -->
                <v-col cols="12">
                  <InputSelect
                    :title="changeName(profile)"
                    :text="profile.name"
                    :name="profile.profile_name"
                    :placeholder="profile.name"
                    valueField="englishName"
                    textField="englishName"
                    v-bind:options="country"
                    v-model:value="form.country"
                    :isRequired="profile.required"
                    class="f-regular"
                    :v="field"
                    :validateText="profile.validate_text"
                    :isValidate="field.$error"
                    @change="val => changeLocale(val, true)"
                  />
                </v-col>
              </v-row>
            </div>
            <div v-else-if="profile.field_type_id == 4" class="margin-input">
              <v-row no-gutters :id="'select-' + profile.profile_name">
                <!-- <v-col cols="12" class="pb-0">
                  <label class="mb-0">
                    {{ changeName(profile) }}
                    <span v-if="profile.required" class="text-danger">*</span>
                  </label>
                </v-col> -->
                <v-col>
                  <InputSelect
                    :title="changeName(profile)"
                    :text="changeName(profile)"
                    :name="profile.profile_name"
                    :placeholder="changeName(profile)"
                    valueField="name"
                    textField="display_name"
                    v-bind:options="profile.field_choices"
                    v-model:value="
                      form[checkIsProfile(profile.field_profile_type_id)]
                    "
                    :isRequired="profile.required"
                    class="f-regular"
                    :class="isOtherField(profile) ? 'pr-2' : ''"
                    :isValidate="
                      v$.form[checkIsProfile(profile.field_profile_type_id)]
                        .$error
                    "
                    :v="v$.form[checkIsProfile(profile.field_profile_type_id)]"
                    @deleteOtherValue="deleteOtherValue(profile)"
                    @setOtherValue="val => $setOtherValue(val, profile)"
                    @change="val => setOtherValue(val, profile)"
                    formType
                    :disabled="disabledDependentField(profile)"
                  />
                </v-col>

                <v-col v-if="isOtherField(profile)">
                  <InputText
                    textFloat=""
                    :placeholder="otherName(profile)"
                    class="margin-input f-regular"
                    :type="checkFieldConditon(profile)"
                    :name="profile.profile_name"
                    size="lg"
                    v-model:value="profile.other_value"
                    :maxlength="checkMaxMin(profile.field_conditions, 2)"
                    :min="checkMaxMin(profile.field_conditions, 3)"
                    :max="checkMaxMin(profile.field_conditions, 4)"
                    :isValidate="
                      v$.form[checkIsProfile(profile.field_profile_type_id)]
                        .$error
                    "
                    :disabled="profile.field_profile_type_id == 10"
                    :v="v$.form[checkIsProfile(profile.field_profile_type_id)]"
                    @onDataChange="isMaxMin"
                    @onkeypress="isNumber"
                    isOtherField
                  />
                </v-col>
              </v-row>
            </div>
            <div v-else-if="profile.field_type_id == 15" class="margin-input">
              <InputRadio
                v-if="profile.field_profile_type_id == 27"
                :id="`${profile.id}`"
                :options="profile.field_choices"
                value-field="name"
                text-field="name"
                v-model:value="
                  form[checkIsProfile(profile.field_profile_type_id)]
                "
                :v="field"
                :validateText="profile.validate_text"
                :isValidate="field.$error"
                @change="val => handleChange(val, profile)"
              ></InputRadio>

              <InputSelect
                v-else
                :title="changeName(profile)"
                :text="changeName(profile)"
                :name="profile.profile_name"
                :placeholder="changeName(profile)"
                valueField="name"
                textField="name"
                v-bind:options="profile.field_choices"
                v-model:value="
                  form[checkIsProfile(profile.field_profile_type_id)]
                "
                @change="val => handleChange(val, profile)"
                :isRequired="profile.required"
                class="f-regular"
                :isValidate="field.$error"
                :v="field"
                :disabled="disabledDependentField(profile)"
                formType
              />
            </div>
            <div v-else-if="profile.field_type_id == 7">
              <v-row no-gutters class="container-birthday-fields">
                <v-col
                  cols="12"
                  class="py-0"
                  v-if="!lineLayout.includes('Minimal')"
                >
                  <label for="" class="custom-label mb-0">
                    {{ profile.field_language[0].name }}
                    {{
                      `(${$t("year")} ${
                        yearFormat == 1 ? $t("year_be") : $t("year_ad")
                      })`
                    }}
                    <span v-if="profile.required" class="text-danger">* </span>
                  </label>
                </v-col>

                <v-col
                  cols="4"
                  :class="{ 'pr-1': !lineLayout.includes('Minimal') }"
                >
                  <v-select
                    v-model="form.birthday_day"
                    :model-value="form.birthday_day || null"
                    :items="day"
                    :label="birthDayLabel(profile.required, 'day')"
                    @change="handleDayChange"
                    :single-line="!lineLayout.includes('Minimal')"
                    hide-details
                    :error="
                      v$.isOldEnough?.$error || v$.form.birthday_day.$error
                    "
                    :disabled="birthdayDisabled"
                    :style="{
                      color: v$.isOldEnough?.$error ? 'red' : ''
                    }"
                    style="--v-field-padding-top: 3px"
                    name="day"
                    :placeholder="$t('day')"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item value="" disabled selected>
                        <span>
                          <slot name="options-name"> {{ $t("day") }}</slot>
                        </span>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="4"
                  :class="{ 'px-1': !lineLayout.includes('Minimal') }"
                >
                  <v-select
                    v-model="form.birthday_month"
                    :model-value="form.birthday_month || null"
                    :items="month"
                    @change="handleMonth"
                    :label="birthDayLabel(profile.required, 'month')"
                    :single-line="!lineLayout.includes('Minimal')"
                    hide-details
                    :placeholder="$t('month')"
                    :error="
                      v$.isOldEnough?.$error || v$.form.birthday_month.$error
                    "
                    :style="{
                      color: v$.isOldEnough?.$error ? 'red' : '',
                      '--v-field-padding-top': '3px'
                    }"
                    :disabled="birthdayDisabled"
                    name="month"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item value="" disabled selected>
                        <span>
                          <slot name="options-name"> {{ $t("month") }}</slot>
                        </span>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>

                <v-col
                  cols="4"
                  :class="{ 'pl-1': !lineLayout.includes('Minimal') }"
                >
                  <InputSelect
                    v-model:value="form.birthday_year"
                    v-bind:options="birthYearList"
                    :placeholder="$t('year')"
                    variant="outlined"
                    :title="birthDayLabel(profile.required, 'year')"
                    :single-line="!lineLayout.includes('Minimal')"
                    auto-select-first
                    :isValidate="
                      v$.isOldEnough?.$error || v$.form.birthday_year.$error
                    "
                    customPlaceholder
                    name="year"
                    :disabled="birthdayDisabled"
                  ></InputSelect>
                  <!-- <InputText
                    v-else
                    v-model:value="v$.form.birthday_year.$model"
                    v-bind:options="birthYearList"
                    :placeholder="$t('year')"
                    variant="outlined"
                    type="number"
                    :textFloat="birthDayLabel(profile.required, 'year')"
                    :isValidate="v$.form.birthday_year.$error"
                    :v="v$.form.birthday_year"
                    name="year"
                    :oninput="birthDayInput"
                    :disabled="birthdayDisabled"
                    skip
                  /> -->
                </v-col>
                <v-col
                  cols="12"
                  v-if="
                    !lineLayout.includes('Minimal') &&
                    (v$.form.birthday_year.$error ||
                      v$.form.birthday_month.$error ||
                      v$.form.birthday_day.$error)
                  "
                >
                  <div>
                    <span class="text-error">
                      {{ $t("default_input_validate") }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-else-if="profile.field_type_id == 8">
              <label v-if="changeName(profile)">
                {{ changeName(profile) }}

                <span v-if="profile.required" class="text-danger">*</span>
              </label>

              <FormUploadImage
                :id="profile.id"
                :label="changeName(profile)"
                :img="form[checkIsProfile(profile.field_profile_type_id)]"
                v-model:value="
                  v$.form[checkIsProfile(profile.field_profile_type_id)].$model
                "
                :v="field"
                @setFileName="
                  val => setImageName(val, profile.field_profile_type_id)
                "
                :validateText="field.validate_text"
                :isValidate="field.$error"
              />
              <div class="f-xs color-gray mt-1">
                ไฟล์ที่อัพโหลดต้องเป็นไฟล์นามสกุล PDF, PNG ,JPG ขนาดไม่เกิน 2.5
                mb เท่านั้น
              </div>
            </div>
            <div v-else-if="profile.field_type_id == 12">
              <InputTextArea
                :id="field.id"
                :textFloat="changeName(profile)"
                :placeholder="changeName(profile)"
                type="text"
                class="mt-3 custom-input"
                rows="3"
                v-model:value="
                  form[checkIsProfile(profile.field_profile_type_id)]
                "
                :isRequired="profile.required"
                :isValidate="field.$error"
                :v="field"
              />
            </div>
          </div>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import InputTextArea from "@/components/input/InputTextArea";
import { fieldInForm2 } from "@/assets/json/fieldProfile.js";
import FormUploadImage from "@/components/input/FormUploadImage";
// import { required } from '@vuelidate/validators';
const country = require("@/lang/country.json");
export default {
  components: {
    FormUploadImage,
    InputTextArea
  },
  props: {
    componentKey: { required: false, type: Boolean, default: false },
    fields: { required: true },
    form: { required: true },
    v$: {
      required: false
    },
    isRegister: { required: false, default: false, type: Boolean },
    saveIDCard: {
      required: false
    },
    emailFirst: {
      required: false
    },
    birthDayFirst: {
      required: false
    },
    telephoneFirst: {
      required: false
    },
    group: {
      required: false
    }
  },
  data() {
    return {
      day: [
        { value: "01", title: "01", disabled: false },
        { value: "02", title: "02", disabled: false },
        { value: "03", title: "03", disabled: false },
        { value: "04", title: "04", disabled: false },
        { value: "05", title: "05", disabled: false },
        { value: "06", title: "06", disabled: false },
        { value: "07", title: "07", disabled: false },
        { value: "08", title: "08", disabled: false },
        { value: "09", title: "09", disabled: false },
        { value: "10", title: "10", disabled: false },
        { value: "11", title: "11", disabled: false },
        { value: "12", title: "12", disabled: false },
        { value: "13", title: "13", disabled: false },
        { value: "14", title: "14", disabled: false },
        { value: "15", title: "15", disabled: false },
        { value: "16", title: "16", disabled: false },
        { value: "17", title: "17", disabled: false },
        { value: "18", title: "18", disabled: false },
        { value: "19", title: "19", disabled: false },
        { value: "20", title: "20", disabled: false },
        { value: "21", title: "21", disabled: false },
        { value: "22", title: "22", disabled: false },
        { value: "23", title: "23", disabled: false },
        { value: "24", title: "24", disabled: false },
        { value: "25", title: "25", disabled: false },
        { value: "26", title: "26", disabled: false },
        { value: "27", title: "27", disabled: false },
        { value: "28", title: "28", disabled: false },
        { value: "29", title: "29", disabled: false },
        { value: "30", title: "30", disabled: false },
        { value: "31", title: "31", disabled: false }
      ],
      month: [
        { value: "01", title: "มกราคม", disabled: false },
        { value: "02", title: "กุมภาพันธ์", disabled: false },
        { value: "03", title: "มีนาคม", disabled: false },
        { value: "04", title: "เมษายน", disabled: false },
        { value: "05", title: "พฤษภาคม", disabled: false },
        { value: "06", title: "มิถุนายน", disabled: false },
        { value: "07", title: "กรกฎาคม", disabled: false },
        { value: "08", title: "สิงหาคม", disabled: false },
        { value: "09", title: "กันยายน", disabled: false },
        { value: "10", title: "ตุลาคม", disabled: false },
        { value: "11", title: "พฤศจิกายน", disabled: false },
        { value: "12", title: "ธันวาคม", disabled: false }
      ],
      isAddOptionalTelephone: false,
      cloneFields: [],
      birthYearList: [],
      country,
      menu: false
    };
  },
  computed: {
    isEdit() {
      return this.$route.path == "/manage/profile";
    },
    loginType() {
      return this.form.login_type == 0
        ? this.$route.query.login_type
        : this.form.login_type;
    },
    birthday() {
      return this.form.birthday
        ? this.$moment(this.form.birthday)
            .subtract(543, "year")
            .format("DD/MM/YYYY")
        : null;
    },
    birthdayDisabled() {
      return this.lineLayout.includes("Minimal") && this.birthDayFirst != "";
    }
  },
  async mounted() {
    this.cloneFields = JSON.parse(JSON.stringify(this.fields));
    if (!this.isRegister) await this.loadingField();
    else if (this.isRegister && this.$route.query.usertype == 2) {
      if (this.group == 1)
        this.handleChange(
          "จดทะเบียน",
          this.fields.find(el => el.field_profile_type_id == 27)
        );
    }
    const years = (back, minAge) => {
      const year = this.$moment().years();
      return Array.from({ length: back }, (v, i) => year - back + i + 1)
        .sort((a, b) => b - a)
        .filter(el => el <= minAge);
    };
    const month = () => {
      if (this.lineLayout.includes("Minimal"))
        return Array.apply(0, Array(12)).map((_, i) => {
          return {
            value: this.moment().locale("en").month(i).format("MM"),
            title: this.moment().locale("en").month(i).format("MMMM")
          };
        });
      else
        return Array.apply(0, Array(12)).map((_, i) => {
          return {
            value: this.$moment().month(i).format("MM"),
            title: this.$moment().month(i).format("MMMM")
          };
        });
    };
    const thisYear = this.$moment().year();
    let field = this.cloneFields.find(el => el.field_type_id == 7);
    if (field) {
      let minYear = Number(thisYear);
      if (field.field_conditions[1]) {
        minYear = Number(thisYear) - Number(field.field_conditions[1].value);
      }

      this.birthYearList = years(
        Number(field.field_conditions[0].value) + 1,
        minYear
      );
    }
    this.month = month();
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`; // เปลี่ยนเป็น DD/MM/YYYY
    },
    setValue(val, key) {
      this.form[key] = val.target.value;
    },
    deleteOtherValue(profile) {
      delete profile.other_value;
    },
    checkFieldConditon(obj) {
      return obj.field_conditions[0] ? obj.field_conditions[0].value : "text";
    },
    checkIsProfile(val) {
      if (val == 99) val = 22;
      const findField = fieldInForm2.find(el => el.id == val);
      return findField ? findField.key : false;
    },
    checkMaxMin(item, key, field_id) {
      if (field_id == 9) {
        const condition = item.find(el => el.field_condition_type_id == 7);

        if (condition)
          if (key == 3) {
            return condition.value
              ? this.$moment()
                  .subtract(condition.value, "year")
                  .format("YYYY-01-01")
              : false;
          } else if (key == 4) return this.$moment().format("YYYY-MM-DD");
      }
      const conditon = item.find(el => el.field_condition_type_id == key);
      if (conditon) return conditon.value ? conditon.value : false;
      return false;
    },
    isMaxMin: function (value, e) {
      try {
        const val = e.target.value;

        const max = Number(e.target.max);
        const min = Number(e.target.min);
        if (
          val &&
          (e.target.type == "number" || e.target.type == "Number") &&
          min &&
          val < min
        ) {
          return (e.target.value = min);
        }
        if (
          val &&
          (e.target.type == "number" || e.target.type == "Number") &&
          max &&
          val > max
        ) {
          return (e.target.value = max);
        }
      } catch (error) {
        console.log(error);
      }
    },
    isNumber: function (e) {
      const maxlen = e.target.maxLength;
      const val = e.target.value;

      if (
        (e.key == "e" || e.charCode == 45 || e.charCode == 46) &&
        (e.target.type == "number" || e.target.type == "Number")
      ) {
        return e.preventDefault();
      }
      if (maxlen == -1) {
        return val;
      }

      if (val.length > maxlen - 1) {
        return e.preventDefault();
      }
    },
    handleChangeGender(val) {
      this.form.gender = val;
    },

    async handleChange(val, field, init = false) {
      if (field) {
        let payload = field.field_choices.find(el => el.name == val);
        let $keyset = this.checkIsProfile(field.field_profile_type_id);

        if (payload)
          if (payload.denpendent.length > 0) {
            let $set = this.fields.findIndex(
              el => el.id == field.field_dependent
            );

            if (!init && $keyset == "company_information") {
              this.form.company_type = "";
              this.form.prefix = "";
            }

            if (!init) {
              this.form[
                this.checkIsProfile(this.fields[$set].field_profile_type_id)
              ] = "";
            }
            this.fields[$set].name = payload.description || payload.name;
            this.fields[$set].field_choices = payload.denpendent.map(el => {
              return {
                ...el,
                name: el.choice_dependent_name,
                description: el.name,
                denpendent: el.level3,
                is_display: 1
              };
            });
          } else {
            let $set = this.fields.findIndex(
              el => el.id == field.field_dependent
            );

            if ($keyset == "province") {
              let $set1 = this.fields.findIndex(el => el.id == 16);
              this.fields[$set1].field_choices = [];
              this.form.district = "";
              this.form.subdistrict = "";
              this.form.zip_code = "";
            }
            if ($keyset == "district") {
              this.form.subdistrict = "";
              this.form.zip_code = "";
            }
            if ($keyset == "subdistrict") {
              this.form.zip_code = "";
            }
            if (this.fields[$set].field_type_id == 1)
              this.form[
                this.checkIsProfile(this.fields[$set].field_profile_type_id)
              ] = this.form.zip_code || payload.zip_code;
            else {
              const result = await this.$axios(
                `/api/v1/Service/Getsubaddress/${payload.id}/${
                  field.id == 18 ? 1 : 2
                }`
              );

              this.fields[$set].field_choices =
                result.detail[
                  this.checkIsProfile(this.fields[$set].field_profile_type_id)
                ];
            }
          }
      }
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    handleBirthYear(val, e) {
      let year = this.$moment().year();
      if (val > year) {
        e.target.value = year;
        this.form.birthday_year = year;
      }
    },
    editPhone(value) {
      let type = value == 11 ? 2 : 1;
      this.$store.commit("setEditPhoneNumber", true);
      this.$nextTick(() => {
        this.$router.push({
          path: "/manage/profile/edit/" + type,
          query: { tel: this.form.telephone, email: this.form.email }
        });
      });
    },

    disabledDependentField($field) {
      let id = $field.field_profile_type_id;

      let $disable = this.fields.find(el => el.field_dependent == id);

      if ($field.field_choices)
        if ($disable && $field.field_choices.length > 0) return false;
      return $disable ? true : false;
    },
    changeName($fields) {
      if ($fields.field_language[0])
        return $fields.field_language[0].name || $fields.name;
      return $fields.name;
    },
    otherName($fields) {
      return $fields.other_name || $fields.field_language[0].name;
    },
    $setOtherValue(val, profile) {
      profile.other_value = val;
    },
    isOtherField($fields) {
      if ($fields) {
        let find = $fields.field_choices
          ? $fields.field_choices.find(
              el =>
                el.name ==
                this.form[this.checkIsProfile($fields.field_profile_type_id)]
            )
          : null;
        return find ? (find.choice_type_id == 3 ? true : false) : false;
      }
    },
    async setField(val, field, payload) {
      let $keyset = this.checkIsProfile(field.field_profile_type_id);

      if (payload) {
        let $set = this.fields.findIndex(
          el => el.field_profile_type_id == field.field_dependent
        );

        if (this.fields[$set].field_type_id == 1)
          this.form[
            this.checkIsProfile(this.fields[$set].field_profile_type_id)
          ] = this.form.zip_code || payload.zip_code;
        else {
          const result = await this.$axios(
            `/api/v1/Service/Getsubaddress/${payload.id}/${
              $keyset == "province" ? 1 : 2
            }`
          );
          let fieldKey = this.checkIsProfile(
            this.fields[$set].field_profile_type_id
          );
          this.$nextTick(
            () =>
              (this.fields[$set].field_choices =
                result.detail[
                  fieldKey == "sub_district" ? "subdistrict" : fieldKey
                ])
          );
        }
      }
    },
    async loadingField() {
      this.isLoading = true;
      this.$nextTick(async () => {
        for (const $fields of this.fields) {
          let value =
            this.form[this.checkIsProfile($fields.field_profile_type_id)];

          if ($fields.field_company_type_id == 27) {
            await this.handleChange(
              this.form.company_information,
              $fields,
              true
            );
          } else if ($fields.field_company_type_id == 28) {
            await this.handleChange(this.form.company_type, $fields, true);
          } else if ($fields.field_type_id == 15) {
            if (value) {
              let payload = await $fields.field_choices.find(
                el => el.name == value
              );

              await this.setField(value, $fields, payload);
            }
          }
        }
        await this.changeLocale(this.form.country);
      });
      this.isLoading = false;
    },
    async changeLocale(val, clearField = false) {
      let keyAddress = [
        "home_address",
        "zip_code",
        "subdistrict",
        "district",
        "province",
        "road",
        "alley",
        "town"
      ];
      let changeFieldType = ["subdistrict", "district", "province"];
      let addressInput = fieldInForm2.find(el => el.key == "home_address").id;
      let choiceValue = val != "ประเทศไทย";

      let addressField = fieldInForm2
        .filter(el => keyAddress.includes(el.key))
        .map(el => el.id);
      let changeField = fieldInForm2
        .filter(el => changeFieldType.includes(el.key))
        .map(el => el.id);
      let index = 0;
      for (const fields of this.fields) {
        if (addressField.includes(fields.field_profile_type_id)) {
          if (choiceValue) {
            if (changeField.includes(fields.field_profile_type_id))
              fields.field_type_id = 1;
          } else {
            if (changeField.includes(fields.field_profile_type_id))
              fields.field_type_id = 15;
          }
          fields.is_address_display = true;
          fields.required = choiceValue
            ? false
            : this.cloneFields[index].required;
          if (clearField) {
            this.form[this.checkIsProfile(fields.field_profile_type_id)] = "";

            if (!choiceValue)
              if (changeField)
                if (fields.field_profile_type_id == 18)
                  fields.field_choices = await this.$store.dispatch(
                    "getProvince"
                  );
                else fields.field_choices = [];
          }
        }

        index++;
      }
    },
    async setOtherValue(val, profile) {
      if (val.type) return;
      if (val) {
        let desc = profile.field_choices.find(el => el.name == val);
        profile.other_name = desc.description;
        await this.$nextTick();
      }
    },
    async setImageName(val, id) {
      let res = await this.checkIsProfile(id);
      if (res == "id_card_image") this.form.id_card_file_name = val;
    },
    checkIDCard() {
      if (this.saveIDCard == null) return false;
      else if (this.saveIDCard == "") return false;
      return true;
    },
    disabledCondition(profile) {
      if (
        profile.field_profile_type_id == 10 ||
        profile.field_profile_type_id == 11
      ) {
        if (this.isRegister) {
          if (this.loginType == 1 && profile.field_profile_type_id == 10)
            return true;
          else if (this.loginType == 2 && profile.field_profile_type_id == 11)
            return true;
          return false;
        }
        if (profile.field_profile_type_id == 11 && this.emailFirst) return true;
        if (profile.field_profile_type_id == 10 && this.telephoneFirst)
          return true;
        if (profile.field_profile_type_id == 1 && this.checkIDCard())
          return true;
        if (profile.field_profile_type_id == 10 && !this.isRegister)
          return true;
      }
      return false;
    },
    birthDayLabel(required, key) {
      if (!this.lineLayout.includes("Minimal")) return;
      let req = required ? " *" : "";
      return this.$t(key) + req;
    },
    birthDayInput(e) {
      if (e.target.value.length > 4) {
        e.target.value = e.target.value.slice(0, 4);
        this.form.birthday_year = e.target.value;
        return e.preventDefault();
      }
    }
  }
};
</script>

<style>
.box-tel {
  color: #575757;
  margin: 0;
  height: 45px;
  background: white;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
}
</style>
