<template>
  <div class="px-3 pt-3 space-bottom">
    <div class="f-xl f-bold mb-3" data-lang="your_telephone">
      {{ $t("your_telephone") }}
    </div>

    <label class="mb-2" data-lang="main_telephone">{{
      $t("main_telephone")
    }}</label>

    <v-row class="telephone-box">
      <v-col class="p-0"
        >({{ form.telephone_country_dial_code }}) {{ form.telephone }}</v-col
      >
      <v-col class="text-right pr-0">
        <!-- <font-awesome-icon :icon="['fas', 'chevron-right']" /> -->
      </v-col>
    </v-row>

    <template v-for="($optTel, index) of list" :key="'count-' + index">
      <InputTelephonWithCountry
        v-model:value="$optTel.value"
        removeIcon
        textFloat=""
        class="mt-2"
        @delete="removeTelephone(index)"
      />
    </template>
    <div
      class="text-center color-primary optional-telephone"
      :class="
        $config.limit_secondary_telephone <= list.length &&
        $config.limit_secondary_telephone != 0
          ? 'disabled'
          : ''
      "
      @click="addOptional(index)"
    >
      <font-awesome-icon :icon="['fas', 'plus']" class="mr-2" />
      <span data-lang="add">{{ $t("add") }}</span>
      <span data-lang="optional_telephone">{{ $t("optional_telephone") }}</span>
      <template v-if="$config.limit_secondary_telephone > 0">
        ({{ list.length }}/{{ $config.limit_secondary_telephone }})
      </template>
    </div>
  </div>

  <div
    class="register-bottom fixed-bottom shadow"
    :class="{ 'bg-white': !lineLayout.includes('Minimal') }"
  >
    <c-btn
      class="w-100"
      variant="confirm-primary"
      @click="saveOptionalTelephone()"
      ><span data-lang="save">{{ $t("save") }}</span>
    </c-btn>
    <c-btn class="w-100" variant="custom-link" @click="$emit('close')"
      ><span data-lang="close">{{ $t("close") }}</span></c-btn
    >
  </div>
</template>

<script>
import InputTelephonWithCountry from "@/components/input/InputTelephoneWithCountry.vue";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  minLength,
  maxLength,
  numeric,
  requiredIf,
  helpers
} from "@vuelidate/validators";
export default {
  components: {
    InputTelephonWithCountry
  },
  props: {
    form: {
      required: true
    }
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    const checkValue = (val1, v, a) => {
      if (!val1) return true;
      else return false;
    };
    return {
      list: {
        $each: helpers.forEach({
          value: {
            required: requiredIf(checkValue),
            // maxLength: maxLength(10),
            // minLength: minLength(10),
            numeric
          }
        })
      }
    };
  },
  data() {
    return {
      list: []
    };
  },
  mounted() {
    let value = this.form.optional_telephone.split(",");
    this.list = value
      .filter(el => el != "")
      .map(el => {
        return { value: el };
      });
  },
  methods: {
    addOptional(index) {
      if (this.$config.limit_secondary_telephone == 0)
        return this.list.push({ value: "" });
      if (this.$config.limit_secondary_telephone <= this.list.length) return;
      this.list.push({ value: "" });
    },
    input(e, index) {
      // console.log(val, e);
      if (e.target.value.length > 10) {
        e.stopPropagation();

        // this.form.Telephone = e.target.value;
        // this.$nextTick(() => (e.target.value = e.target.value.slice(0, 10)));
        // this.list[index].value = e.target.value.slice(0, 10);
      }
    },
    saveOptionalTelephone() {
      // this.v$.list.$touch();
      // if (this.v$.list.$error) return;
      this.$emit(
        "saveOptionalTelephone",
        this.list.map(el => el.value)
      );
    },
    removeTelephone(index) {
      this.list.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.telephone-box {
  padding: 0 10px;

  color: #575757;
  margin: 0;
  height: 45px;
  background: var(--disable-color);
  align-items: center;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
}
.remove-telephone {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.optional-telephone {
  border: 1px dashed;
  color: var(--primary-color);
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: var(--text-lg);
  cursor: pointer;
  transition: 0.25s all linear;
  &.disabled {
    background-color: var(--disable-color);
    color: white;
  }
}
.space-bottom {
  padding-bottom: 90px;
}
</style>
