<template>
  <!-- <pre>{{ socialField }}</pre> -->
  <div v-if="isLoading">
    <OtherLoading variant="dark" />
  </div>

  <template v-else>
    <div class="content-edit-profile space-footer">
      <template v-if="!isAddOptionalTelephone">
        <ProfileForm
          :fields="customField.filter(el => el.group_id == 1)"
          :v$="v$"
          :form="form"
          @addOptionalTelephone="addOptionalTelephone"
          :saveIDCard="saveIDCard"
          :isEdit="isEdit"
          :componentKey="componentKey"
        />
        <ProfileForm
          :fields="customField.filter(el => el.group_id == 2)"
          :v$="v$"
          :form="form"
          :componentKey="componentKey"
        />
        <DynamicForm
          :fields="socialField"
          :v$="v$"
          keyFormValidate="socialField"
        />
      </template>
      <OptionalTelephone
        v-else
        :form="form"
        @saveOptionalTelephone="saveOptionalTelephone"
        @close="closeOptionalTelephone"
      />
    </div>
    <div>
      <div class="consent-container mb-3">
        <v-checkbox
          v-model="form.is_privacy_policy"
          :true-value="1"
          :false-value="0"
          id="term-checkbox"
          density="compact"
          style="--v-icon-size: 12px; --v-selection-control-size: 12px"
          hide-details
          disabled
          :class="$isShowTab || $isMinimalCompact ? 'no-check-icon' : ''"
        >
          <template v-slot:label>
            <div>
              <span
                data-lang="consent_policy_message_email_login_pdpa_email"
                v-if="$route.query.login_type == 2"
              >
                {{ $t("consent_policy_message_email_login_pdpa_email") }}
              </span>
              <span data-lang="consent_policy_message" v-else>
                {{ $t("consent_policy_message") }}
              </span>
              <span data-lang="policy_email" v-if="$route.query.login_type == 2">
                {{ $t("policy_email") }}
              </span>
              <span
                class="text-underline"
                @click.prevent="modalPolicyOpen"
                data-lang="policy"
                v-else
              >
                {{ $t("policy") }}
              </span>
              <span v-if="$isShowTab" class="text-danger">&nbsp;*</span>
            </div>
          </template>
        </v-checkbox>
        <v-checkbox
          v-if="$isMinimalCompact"
          v-model="form.is_consent"
          :true-value="1"
          :false-value="0"
          id="policy-checkbox"
          class="no-check-icon mt-3"
        >
          <template v-slot:label>
            <div>
              <span
                data-lang="consent_term_message_email_login_policy_email"
                v-if="$route.query.login_type == 2"
              >
                {{ $t("consent_term_message_email_login_policy_email") }}&nbsp;
              </span>
              <span data-lang="consent_term_message" v-else>
                {{ $t("consent_term_message") }}&nbsp;
              </span>

              <span data-lang="term_email" v-if="$route.query.login_type == 2">
                {{ $t("policy_email") }}
              </span>
              <span
                class="text-underline"
                @click.prevent="modalPolicyOpen"
                data-lang="term"
                v-else
              >
                {{ $t("policy") }}
              </span>
            </div>
          </template>
        </v-checkbox>
        <v-checkbox
          v-else-if="$isShowTab && $config.$BRAND == 'ORLEBAR BROWN'"
          v-model="form.is_consent"
          :true-value="1"
          :false-value="0"
          id="policy-checkbox"
          class="no-check-icon mt-3"
        >
          <template v-slot:label>
            <p>
              I consent to Orlebar Brown Thailand sharing my personal data with
              Orlebar Brown Limited, for its services and benefits (such as its
              worldwide promise/guarantee) and for direct marketing by Orlebar
              Brown Limited, according to their
              <span class="text-underline" @click.prevent="modalTermsOpen">{{
                $t("term")
              }}</span
              >.
              <br />
              <br />
              I consent to Orlebar Brown Thailand sharing my personal data with
              its related group of companies, for the purpose of direct
              marketing by them for other offerings in Thailand, according to
              the
              <span class="text-underline" @click.prevent="modalPolicyOpen"
                >Privacy Policy.</span
              >
            </p>
          </template>
        </v-checkbox>
      </div>
    </div>
    <div
      :class="{ 'bg-white': !lineLayout.includes('Minimal') }"
      v-if="!isAddOptionalTelephone"
    >
      <c-btn
        class="w-100"
        @click="validateEmail()"
        variant="confirm-primary"
        :disabled="disabledBtn || isDisabledButton"
      >
        {{ $t("save") }}
      </c-btn>
    </div>

    <ModalPolicy ref="modalPolicy" />
    <ModalTermAndConditios ref="modalTerms" />
  </template>
  <ModalError ref="modalError" :text="successMessage" />
</template>

<script>
import ModalError from "@/components/alert-modal/ModalError";
const moment = require("moment");
import OtherLoading from "@/components/other/OtherLoading";
import ModalPolicy from "@/components/alert-modal/ModalPolicy";
import ModalTermAndConditios from "@/components/alert-modal/ModalTermAndConditios";
import { fieldInForm2 } from "@/assets/json/fieldProfile.js";
import ProfileForm from "@/components/form/ProfileForm.vue";

import PreviewCustomObject from "@/views/page/customForm/detail.vue";
import DynamicForm from "@/components/form/DynamicForm.vue";
import OptionalTelephone from "@/components/form/OptionalTelephone.vue";
import InvoiceAddress from "@/components/form/InvoiceAddress";
import ShippingAddress from "@/components/form/ShippingAddress";
import ContactAddress from "@/components/form/ContactAddress";
export default {
  components: {
    ModalError,
    OtherLoading,
    ModalPolicy,
    ModalTermAndConditios,
    PreviewCustomObject,
    ProfileForm,
    DynamicForm,
    OptionalTelephone,
    InvoiceAddress,
    ShippingAddress,
    ContactAddress
  },
  props: {
    form: {
      required: true,
      type: Object
    },

    hideTerm: {
      required: false
    },
    consentShow: {
      required: true,
      type: Boolean
    },
    customField: {
      required: true,
      type: Array
    },
    questionList: {
      required: true,
      type: Array
    },
    v$: {
      required: true,
      type: Object
    },
    isEdit: {
      required: true,
      type: Boolean,
      default: false
    },
    dynamicField: {
      required: false
    },
    socialField: {
      required: false,
      type: Array
    },
    hasSubmit: {
      required: false,
      default: false
    },

    isDisabledButton: {
      required: false
    },
    saveIDCard: {
      required: false
    },
    componentKey: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      successMessage: "",
      emailFirst: "",
      disabledBtn: false,
      displayRightRequest:
        this.$store.state.theme.line_display_right_request_link == "1"
          ? true
          : false,
      day: [
        { value: "01", title: "01", disabled: false },
        { value: "02", title: "02", disabled: false },
        { value: "03", title: "03", disabled: false },
        { value: "04", title: "04", disabled: false },
        { value: "05", title: "05", disabled: false },
        { value: "06", title: "06", disabled: false },
        { value: "07", title: "07", disabled: false },
        { value: "08", title: "08", disabled: false },
        { value: "09", title: "09", disabled: false },
        { value: "10", title: "10", disabled: false },
        { value: "11", title: "11", disabled: false },
        { value: "12", title: "12", disabled: false },
        { value: "13", title: "13", disabled: false },
        { value: "14", title: "14", disabled: false },
        { value: "15", title: "15", disabled: false },
        { value: "16", title: "16", disabled: false },
        { value: "17", title: "17", disabled: false },
        { value: "18", title: "18", disabled: false },
        { value: "19", title: "19", disabled: false },
        { value: "20", title: "20", disabled: false },
        { value: "21", title: "21", disabled: false },
        { value: "22", title: "22", disabled: false },
        { value: "23", title: "23", disabled: false },
        { value: "24", title: "24", disabled: false },
        { value: "25", title: "25", disabled: false },
        { value: "26", title: "26", disabled: false },
        { value: "27", title: "27", disabled: false },
        { value: "28", title: "28", disabled: false },
        { value: "29", title: "29", disabled: false },
        { value: "30", title: "30", disabled: false },
        { value: "31", title: "31", disabled: false }
      ],
      month: [
        { value: "01", title: "มกราคม", disabled: false },
        { value: "02", title: "กุมภาพันธ์", disabled: false },
        { value: "03", title: "มีนาคม", disabled: false },
        { value: "04", title: "เมษายน", disabled: false },
        { value: "05", title: "พฤษภาคม", disabled: false },
        { value: "06", title: "มิถุนายน", disabled: false },
        { value: "07", title: "กรกฎาคม", disabled: false },
        { value: "08", title: "สิงหาคม", disabled: false },
        { value: "09", title: "กันยายน", disabled: false },
        { value: "10", title: "ตุลาคม", disabled: false },
        { value: "11", title: "พฤศจิกายน", disabled: false },
        { value: "12", title: "ธันวาคม", disabled: false }
      ],
      isLoading: false,
      tabIndex: null,
      customObjectForm: [],
      tabsName: "",
      fromDateMenu: false,
      fromDateVal: null,
      panels: [0, 1, 2],
      isAddOptionalTelephone: false
    };
  },
  created() {
    this.emailFirst = this.form.email;
    this.handleMonth(this.form.birthday_month);
    this.handleDayChange(this.form.birthday_day);
    this.getCustomObjectForm();
  },
  computed: {},
  watch: {
    tabIndex(val) {
      if (this.isAddOptionalTelephone)
        return (this.isAddOptionalTelephone = false);
    }
  },
  updated: function () {
    var $this = this;

    if (this.$route.query.id)
      this.$nextTick(function () {
        let index = $this.customObjectForm.findIndex(
          el => el.id == $this.$route.query.id
        );
        if (index != -1) {
          $this.tabIndex = `customObject-${$this.customObjectForm[index].id}`;
          $this.tabsName = $this.customObjectForm[index].name;
          return $this.$router
            .replace(
              `/manage/profile?check=true${
                this.$route.query.view ? "&view=" + this.$route.query.view : ""
              } `
            )
            .catch(err => {});
        }
      });
  },
  methods: {
    saveOptionalTelephone(val) {
      this.form.optional_telephone = val.join(",");
      this.isAddOptionalTelephone = false;
    },
    addOptionalTelephone() {
      this.isAddOptionalTelephone = true;
    },
    closeOptionalTelephone() {
      this.isAddOptionalTelephone = false;
    },
    resetForm() {
      this.v$.dynamicField.$reset();
    },

    handleMonth(vals) {
      let length = this.day.length - 1;
      let disabledMonth = ["04", "06", "09", "11"];
      if (vals == "02") {
        this.day[length].disabled = true;
        this.day[length - 1].disabled = true;
      } else if (disabledMonth.includes(vals)) {
        this.day[length].disabled = true;
        this.day[length - 1].disabled = false;
      } else {
        this.day[length].disabled = false;
        this.day[length - 1].disabled = false;
      }
    },
    handleDayChange(vals) {
      let disabledMonth = ["04", "06", "09", "11"];
      if (vals == 30 || vals == 31) {
        if (vals == 31) {
          this.$nextTick(() => {
            this.month.map(el => {
              if (disabledMonth.includes(el.value)) {
                el.disabled = true;
              }
              return { ...el };
            });
            this.month = this.month;
          });
        } else if (vals == 30) {
          this.month.map(el => {
            el.disabled = false;
            return { ...el };
          });
        }
        this.$nextTick(() => {
          this.month[1].disabled = true;
        });
      } else {
        this.month.map(el => {
          el.disabled = false;
          return { ...el };
        });
      }
    },

    handleLink() {
      if (this.$store.state.theme.is_right_request_url == "1")
        return window.open(this.$store.state.theme.right_request_url, "_blank");

      this.$router.push("/setting");
    },
    checkFieldConditon(obj) {
      return obj.field_conditions[0] ? obj.field_conditions[0].value : "text";
    },
    checkIsProfile(val) {
      const findField = fieldInForm2.find(el => el.id == val);

      return findField ? findField.key : false;
    },
    checkMaxMin(item, key, field_id) {
      if (field_id == 9) {
        const condition = item.find(el => el.field_condition_type_id == 7);

        if (condition)
          if (key == 3) {
            return condition.value
              ? this.$moment()
                  .subtract(condition.value, "year")
                  .format("YYYY-01-01")
              : false;
          } else if (key == 4) return this.$moment().format("YYYY-MM-DD");
      }
      const conditon = item.find(el => el.field_condition_type_id == key);
      if (conditon) return conditon.value ? conditon.value : false;
      return false;
    },
    isMaxMin: function (value, e) {
      try {
        const val = e.target.value;

        const max = Number(e.target.max);
        const min = Number(e.target.min);
        if (val && e.target.type == "number" && min && val < min) {
          return (e.target.value = min);
        }
        if (val && e.target.type == "number" && max && val > max) {
          return (e.target.value = max);
        }
      } catch (error) {
        console.log(error);
      }
    },
    isNumber: function (e) {
      const maxlen = e.target.maxLength;
      const val = e.target.value;

      if (
        (e.key == "e" || e.charCode == 45 || e.charCode == 46) &&
        e.target.type == "number"
      ) {
        return e.preventDefault();
      }
      if (maxlen == -1) {
        return val;
      }

      if (val.length > maxlen - 1) {
        return e.preventDefault();
      }
    },
    handleChangeGender(val) {
      this.form.gender = val;
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    async nextPage() {
      // this.v$.form.$touch();

      this.v$.form.$touch();
      this.v$.socialField.$touch();

      if (this.v$.form.$error || this.v$.socialField.$error) {
        return;
      }
      if (this.dynamicField.length == 0) {
        return this.validateEmail();
      }
      this.tabIndex = 1;
    },
    async validateEmail() {
      if (this.hasSubmit) {
        return this.$emit("onSubmit");
      }

      if (this.form.email !== this.emailFirst) {
        this.v$.form.$touch();
        if (this.v$.form.$error) {
          return;
        }

        let chkEmail = {
          Email: this.form.email
        };
        this.isLoading = true;
        this.$axios.post(`/api/v1/user/ValidateEmail`, chkEmail).then(data => {
          if (data.result == 1) {
            this.validationFormEdit();
          } else {
            this.successMessage = "Email นี้เคยสมัครแล้ว";
            this.$refs.modalError.show();
            this.isLoading = false;
          }
        });
      } else {
        this.validationFormEdit();
      }
    },
    isOtherField($fields) {
      if ($fields) {
        let find = $fields.field_choices
          ? $fields.field_choices.find(
              el =>
                el.name ==
                this.form[this.checkIsProfile($fields.field_profile_type_id)]
            )
          : null;
        return find ? (find.choice_type_id == 3 ? true : false) : false;
      }
    },
    async validationFormEdit() {
      this.v$.form.$touch();

      this.v$.dynamicField.$touch();
      this.v$.socialField.$touch();

      if (
        this.v$.dynamicField.$error ||
        this.v$.form.$error ||
        this.v$.socialField.$error
      ) {
        return;
      }

      let dynamic = [];
      for (const fields of this.dynamicField) {
        let target_id = 0;
        if (fields.field_choices.length > 0) {
          const findFieldId = fields.field_choices.find(
            choice => choice.name == fields.user_answer
          );
          if (findFieldId) {
            target_id = findFieldId.id;
          }
        }
        dynamic.push({
          id: fields.id,
          value: fields.user_answer,
          target_id: target_id
        });
      }
      let objTemp = [];

      for (const x of dynamic) {
        var findField = this.dynamicField.find(field => field.id == x.id);

        if (x.value && typeof x.value == "object") {
          if (x.value.length > 0) {
            for (const a of x.value) {
              const { id } = findField.field_choices.find(
                choice => choice.name == a
              );
              objTemp.push({
                id: x.id,
                value: a,
                target_id: id
              });
            }
          }
        } else {
          objTemp.push(x);
        }
      }

      this.isLoading = true;

      this.disabledBtn = true;

      delete this.form.birthday;

      let payload = {
        ...this.form,
        custom_field: objTemp.filter(el => el.value)
      };

      for (const checkForm of this.customField) {
        if (this.isOtherField(checkForm))
          payload[this.checkIsProfile(checkForm.field_profile_type_id)] =
            checkForm.other_value;
      }

      // payload.custom_field = dynamic;
      let hasBirthDay = this.customField.find(
        el => el.field_profile_type_id == 9
      );

      if (hasBirthDay && this.yearFormat == 1)
        if (payload.birthday_year)
          payload.birthday_year = moment(
            `${payload.birthday_day}/${payload.birthday_month}/${payload.birthday_year}`,
            "DD/MM/YYYY"
          )
            .subtract(543, "y")
            .year();
      if (!payload.birthday_day) payload.birthday_day = "01";
      if (!payload.birthday_month) payload.birthday_month = "01";
      if (!payload.birthday_year) payload.birthday_year = "1753";
      payload.social = this.socialField.map(el => {
        return {
          // ...el,
          id: el.answer_id || 0,
          social_media_type_id: el.id,
          value: el.user_answer,
          user_guid: payload.user_guid
        };
      });

      try {
        const data = await this.$axios.post(
          `/api/v1/user/EditUserProfile`,
          payload
        );

        if (data.result == 1) {
          if (this.customField.length > 0) {
            await this.updateCustomField();
          } else {
            // this.$store.dispatch("refreshUserProfile");
            if (!this.componentKey) this.$router.push("/profile");
            this.$emit("onFinish");
          }
        } else {
          this.disabledBtn = false;
          if (data.detail[0]) {
            this.successMessage = data.detail[0];
          } else {
            this.successMessage = data.message;
          }
          this.$refs.modalError.show();
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error.message);
        this.disabledBtn = false;

        this.successMessage = "เกิดข้อผิดพลาดไม่สามารถแก้ไขข้อมูลได้";

        this.$refs.modalError.show();
        this.isLoading = false;
      }
    },
    async updateCustomField() {
      let body = {
        user_guid: this.form.user_guid,
        admin_user_guid: this.form.user_guid,
        question_list: this.questionList
      };
      const data = await this.$axios.put(
        `/api/v1/User/update_custom_answer`,
        body
      );

      if (data.result == 1) {
        if (!this.componentKey) this.$router.push("/profile");
        this.isLoading = false;
        this.$emit("onFinish");
      } else {
        this.disableBtn = false;
        this.isLoading = false;
        if (data.detail[0]) {
          this.successMessage = data.detail[0];
        } else {
          this.successMessage = data.message;
        }
        this.$refs.modalError.show();
      }
    },
    modalPolicyOpen() {
      if (this.$store.state.theme.is_custom_privacy_link == "1")
        return window.open(this.$store.state.theme.consent_url_link, "_blank");
      this.emitter.emit("showModal", "policy");
    },
    modalTermsOpen() {
      if (this.$store.state.theme.is_term_and_con_url == "1")
        return window.open(this.$store.state.theme.term_and_con_url, "_blank");
      this.emitter.emit("showModal", "term");
      // this.$router.push("/terms");
    },
    async getCustomObjectForm() {
      const resp = await this.$axios.post(
        `/api/CustomObject/custom_object_head`,
        {
          user_guid: "" // อันนี้ของหน้า customer detail
        }
      );

      this.customObjectForm = resp.detail;
    },
    handleTabsChange(name) {
      this.tabsName = name;
      if (this.$route.query.view)
        return this.$router.replace(`/manage/profile?check=true`);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .content-edit-profile {
  // background-color: #fff;
  //   border-radius: 5px;
  // height: 80vh;

  // margin-bottom: 65px;
}

.btn-edit {
  background-color: #000;
  color: #fff;
  width: 100%;
  border: none;
  padding: 10px 0;
}
.remark {
  color: red;
}

.custom-pd-tel {
  padding: 0 10px;
}
.footer-bottom {
  max-width: 800px;
  position: fixed;
  margin: auto;
}
.consent-text {
  font-size: var(--text-2xl);
  color: #575757;
}
.text-underline {
  text-decoration: underline;
}
.div-space {
  margin: 90px 0px;
  background-color: #fff;
}
.label-text {
  color: black;
  font-size: var(--text-2xl);
  margin-bottom: 5px;
  font-weight: 600;
  // color: #838383;
}
.tabs-with-button {
  // height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 227px);
}

.container-tabs {
  // height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 167px);
}

::v-deep .nav {
  width: max-content;
  flex-wrap: unset;
}
::v-deep .nav-wrapper {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
::v-deep .v-window-item.window-padding {
  padding-bottom: 60px;
}
.space-footer {
  padding-bottom: 10px;
}
</style>
